import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items, excludeHome = false }) => {
  // Add Dashboard as the first item if it's not already present
  let breadcrumbItems = items[0]?.label !== 'Dashboard' 
    ? [{ href: '/dashboard', label: 'Dashboard' }, ...items]
    : items;

  // Remove Home item if excludeHome is true
  if (excludeHome) {
    breadcrumbItems = breadcrumbItems.filter(item => item.label !== 'Home');
  }

  return (
    <div className="text-sm breadcrumbs mb-6 pb-4">
      <ul>
        {breadcrumbItems.map((item, index) => (
          <li key={index}>
            {index === breadcrumbItems.length - 1 ? (
              <span className="text-base-content">{item.label}</span>
            ) : (
              <Link to={item.href} className="text-base-content/70 hover:text-base-content">
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
