import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faClock, faServer, faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';

const WhoisInfo = ({ whoisData }) => {
  let parsedData;
  try {
    parsedData = typeof whoisData === 'string' ? JSON.parse(whoisData) : whoisData;
  } catch (error) {
    return <p className="text-error">Error parsing WHOIS data</p>;
  }

  const formatDate = (dateString) => {
    if (Array.isArray(dateString) && dateString.length > 0) {
      dateString = dateString[0];
    }
    if (typeof dateString === 'string') {
      const date = new Date(dateString);
      return date.toLocaleString();
    }
    return 'N/A';
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faGlobe} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Domain Name:</strong> {parsedData.domain_name || 'N/A'}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Creation Date:</strong> {formatDate(parsedData.creation_date)}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faClock} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Updated Date:</strong> {formatDate(parsedData.updated_date)}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Expiry Date:</strong> {formatDate(parsedData.expiration_date)}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faServer} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Registrar:</strong> {parsedData.registrar || 'N/A'}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Emails:</strong> {parsedData.emails || 'N/A'}</p>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faBuilding} className="mr-2 text-base-content" />
        <p className="text-base-content"><strong>Organization:</strong> {parsedData.org || 'N/A'}</p>
      </div>
      {parsedData.name_servers && (
        <div>
          <p className="text-base-content"><strong>Nameservers:</strong></p>
          <ul className="list-disc list-inside pl-6">
            {Array.isArray(parsedData.name_servers)
              ? parsedData.name_servers.map((ns, index) => (
                  <li key={index} className="text-base-content">{ns}</li>
                ))
              : <li className="text-base-content">{parsedData.name_servers}</li>
            }
          </ul>
        </div>
      )}
    </div>
  );
};

const renderMXRecords = (mxRecords) => {
  if (!mxRecords || mxRecords.length === 0) {
    return <p className="text-base-content opacity-70">No MX Records</p>;
  }

  let parsedRecords;
  try {
    parsedRecords = typeof mxRecords === 'string' ? JSON.parse(mxRecords) : mxRecords;
  } catch (error) {
    console.error("Error parsing MX records:", error);
    return <p className="text-base-content opacity-70">Error parsing MX records</p>;
  }

  if (!Array.isArray(parsedRecords)) {
    return <p className="text-base-content opacity-70">Invalid MX records format</p>;
  }

  return (
    <ul className="list-disc list-inside pl-6">
      {parsedRecords.map((record, index) => (
        <li key={index} className="text-base-content">{record}</li>
      ))}
    </ul>
  );
};

const DomainInformation = ({ scanData }) => {
  const renderWhoisInfo = () => {
    if (!scanData.whois) {
      return <p className="text-base-content opacity-70">No WHOIS information available</p>;
    }

    if (scanData.whois.error) {
      return <p className="text-error">{scanData.whois.error}</p>;
    }

    return <WhoisInfo whoisData={scanData.whois} />;
  };

  return (
    <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
      <div className="card-body">
        <h2 className="card-title text-base-content mb-4">
          <FontAwesomeIcon icon={faGlobe} className="mr-2" aria-hidden="true" /> Domain Information
        </h2>
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Domain Details</h3>
          <div className="bg-base-200 p-4 rounded-lg">
            {renderWhoisInfo()}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">MX Records</h3>
          <div className="bg-base-200 p-4 rounded-lg">
            {renderMXRecords(scanData.mx_records)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainInformation;
