import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 300),
    [onSearch]
  );

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  return (
    <div className="w-full max-w-2xl mx-auto mb-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Search by URL..."
          value={searchTerm}
          onChange={handleSearch}
          className="input w-full pl-10 pr-4 bg-base-100 text-base-content placeholder-base-content placeholder-opacity-60 focus:outline-none focus:ring-2 focus:ring-primary border border-base-300 dark:border-base-content/20 hover:border-base-300 dark:hover:border-base-content/30 transition-colors duration-200 shadow-sm hover:shadow-md"
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FontAwesomeIcon icon={faSearch} className="text-base-content opacity-60" />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
