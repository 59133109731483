import React, { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus, faBars, faTimes, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';

const Header = () => {
  const { session } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); // Redirect to the landing page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <nav className="bg-base-100 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <RouterLink to="/">
                  <img className="h-28 w-auto hidden dark:block" src="/logo-dark.svg" alt="QSI AI.url" />
                  <img className="h-28 w-auto dark:hidden" src="/logo-light.svg" alt="QSI AI.url" />
                </RouterLink>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {session && (
                  <RouterLink
                    to="/dashboard"
                    className="border-transparent text-base-content hover:border-base-content hover:text-base-content inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  >
                    Dashboard
                  </RouterLink>
                )}
                <RouterLink
                  to="/recent-scans"
                  className="border-transparent text-base-content hover:border-base-content hover:text-base-content inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Recent Scans
                </RouterLink>
                <RouterLink
                  to="/how-it-works"
                  className="border-transparent text-base-content hover:border-base-content hover:text-base-content inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  How it works
                </RouterLink>
                <RouterLink
                  to="/pricing"
                  className="border-transparent text-base-content hover:border-base-content hover:text-base-content inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Pricing
                </RouterLink>
                <button className="border-transparent text-base-content hover:border-base-content hover:text-base-content inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Blog
                </button>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {session ? (
                <div className="flex items-center">
                  <button
                    onClick={handleLogout}
                    className="ml-3 p-1 rounded-full text-base-content hover:text-base-content focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Logout
                  </button>
                </div>
              ) : (
                <>
                  <RouterLink
                    to="/login"
                    className="bg-base-100 p-1 rounded-full text-base-content hover:text-base-content focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                    Login
                  </RouterLink>
                  <RouterLink
                    to="/signup"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-base-100 bg-primary hover:bg-primary-focus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                    Get started
                  </RouterLink>
                </>
              )}
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-base-content hover:text-base-content hover:bg-base-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <FontAwesomeIcon icon={faTimes} className="block h-6 w-6" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="sm:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1">
                {session && (
                  <RouterLink
                    to="/dashboard"
                    className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                  >
                    Dashboard
                  </RouterLink>
                )}
                <RouterLink
                  to="/recent-scans"
                  className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                >
                  Recent Scans
                </RouterLink>
                <RouterLink
                  to="/how-it-works"
                  className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                >
                  How it works
                </RouterLink>
                <RouterLink
                  to="/pricing"
                  className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                >
                  Pricing
                </RouterLink>
                <button className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200">
                  Blog
                </button>
                {session ? (
                  <button
                    onClick={handleLogout}
                    className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Logout
                  </button>
                ) : (
                  <>
                    <RouterLink
                      to="/login"
                      className="block px-3 py-2 rounded-md text-base font-medium text-base-content hover:text-base-content hover:bg-base-200"
                    >
                      <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                      Login
                    </RouterLink>
                    <RouterLink
                      to="/signup"
                      className="block px-3 py-2 rounded-md text-base font-medium text-base-100 bg-primary hover:bg-primary-focus"
                    >
                      <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                      Get started
                    </RouterLink>
                  </>
                )}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </>
  );
};

export default Header;