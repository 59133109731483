import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet from react-helmet-async
import Header from './Header';
import Footer from './Footer';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>QSI - 404 Not Found</title>
        <meta name="description" content="The requested page could not be found." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Header />

      <main className="flex-grow">
        <div className="bg-base-200 py-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center space-y-8">
              <img
                src="https://nx.qsi.net/storage/v1/object/public/assets/not-found.jpeg?t=2024-09-18T11%3A52%3A57.240Z"
                alt="Not Found"
                className="rounded-lg max-w-full h-auto"
              />
              <div className="space-y-2">
                <h1 className="text-4xl font-bold text-base-content">
                  404 Not Found
                </h1>
                <p className="text-xl text-base-content/70">
                  The page you're looking for doesn't exist.
                </p>
              </div>

              <Link to="/">
                <button className="btn btn-primary btn-lg">
                  Go back to the homepage
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default NotFoundPage;