import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { motion } from 'framer-motion';

const MotionDiv = motion.div;

const PolicyCard = ({ title, content }) => (
  <MotionDiv
    className="card bg-base-100 shadow-xl"
    whileHover={{ scale: 1.05 }}
    transition={{ duration: 0.3 }}
  >
    <div className="card-body">
      <h3 className="card-title text-base-content">{title}</h3>
      <p className="text-base-content/70">{content}</p>
    </div>
  </MotionDiv>
);

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>QSI - Privacy Policy</title>
        <meta name="description" content="Privacy Policy for QSI" />
      </Helmet>
      <Header />
      
      <main className="flex-grow">
        <div className="bg-base-100 py-16">
          <div className="container mx-auto px-4">
            <div className="space-y-8">
              <h1 className="text-4xl font-bold text-center text-base-content">
                Privacy Policy
              </h1>
              <p className="text-lg text-center text-base-content/70">
                At QSI, we are committed to protecting your privacy and ensuring the security of your personal information.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <PolicyCard
                  title="Information We Collect"
                  content="We collect information you provide directly to us, such as when you create an account, use our services, or contact us for support. This may include your name, email address, and the URLs you submit for analysis."
                />
                <PolicyCard
                  title="How We Use Your Information"
                  content="We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to detect and prevent fraud or abuse of our platform."
                />
                <PolicyCard
                  title="Data Security"
                  content="We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage."
                />
                <PolicyCard
                  title="Data Retention"
                  content="We retain your personal information for as long as necessary to provide you with our services and as required by applicable laws. You can request deletion of your account and associated data at any time."
                />
                <PolicyCard
                  title="Third-Party Services"
                  content="We may use third-party services to help us operate our business and the QSI website. These services may have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose."
                />
                <PolicyCard
                  title="Your Rights"
                  content="You have the right to access, correct, or delete your personal information. You can also object to or restrict certain processing of your data. To exercise these rights, please contact us using the information provided below."
                />
              </div>

              <div className="space-y-6 mt-12">
                <h2 className="text-2xl font-bold text-base-content">Changes to This Policy</h2>
                <p className="text-base-content/70">
                  We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
                </p>

                <h2 className="text-2xl font-bold text-base-content">Contact Us</h2>
                <p className="text-base-content/70">
                  If you have any questions about this Privacy Policy, please contact us at dan@qsi.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;