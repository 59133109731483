import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLink, faInfoCircle, faShieldAlt, faBolt, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { validateUsername } from '../utils/validateUsername';

const FirstTimeLoginModal = ({ isOpen, onClose, onComplete, userData }) => {
  const [name, setName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/570195f7-cd79-4833-4a1f-e75873363300/fullsize');
  const [step, setStep] = useState(1);
  const [nameError, setNameError] = useState('');
  const navigate = useNavigate();
  const { setUserData } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      setName(userData.name || '');
      setAvatarUrl(userData.avatar_url || 'https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/570195f7-cd79-4833-4a1f-e75873363300/fullsize');
    }
  }, [userData]);

  const handleNextStep = async () => {
    const error = await validateUsername(name.trim(), userData?.id);
    
    if (error) {
      setNameError(error);
      return;
    }
    
    setNameError('');
    setStep(2);
  };

  const isFreeTier = userData?.plan_id === 'fd5938fb-a785-4330-9e50-29bec31c6533';

  const renderApiKey = () => {
    if (!userData) return null;

    return (
      <div className={`${isFreeTier ? 'opacity-50' : ''}`}>
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Your API Key</h3>
        <p className="font-mono text-sm text-gray-700 dark:text-gray-300">
          {isFreeTier ? '••••••••••••••••' : (userData.api_key || 'Not available')}
        </p>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {isFreeTier 
            ? "API access is not available in the Free tier. Upgrade for API access." 
            : "Keep this key safe and don't share it with others."}
        </p>
      </div>
    );
  };

  const handleComplete = async () => {
    await updateFirstLoginState();
    onComplete(name, avatarUrl);
    navigate('/dashboard');
  };

  const handleViewPricing = async () => {
    await updateFirstLoginState();
    onComplete(name, avatarUrl); // Use onComplete instead of onClose
    navigate('/pricing');
  };

  const updateFirstLoginState = async () => {
    const { error } = await supabase
      .from('users')
      .update({ first_login: false })
      .eq('id', userData.id);

    if (error) {
      console.error('Error updating first_login state:', error);
    } else {
      setUserData(prevData => ({ ...prevData, first_login: false }));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box relative bg-base-100 max-w-2xl">
        <h3 className="text-2xl font-bold mb-6 flex items-center gap-3">
          <FontAwesomeIcon icon={faShieldAlt} className="text-secondary" />
          Welcome to QSI!
        </h3>

        {step === 1 ? (
          <div className="space-y-4">
            <h4 className="text-lg font-semibold">Let's set up your profile</h4>
            
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text flex items-center gap-2">
                  <FontAwesomeIcon icon={faUser} />
                  Your Name
                </span>
              </label>
              <input 
                type="text"
                className={`input input-bordered w-full ${nameError ? 'input-error' : ''}`}
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && (
                <label className="label">
                  <span className="label-text-alt text-error">{nameError}</span>
                </label>
              )}
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text flex items-center gap-2">
                  <FontAwesomeIcon icon={faLink} />
                  Avatar URL (optional)
                </span>
              </label>
              <input 
                type="text"
                className="input input-bordered w-full"
                placeholder="Enter avatar URL"
                value={avatarUrl}
                onChange={(e) => setAvatarUrl(e.target.value)}
              />
            </div>

            {avatarUrl && (
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={avatarUrl} alt="Avatar" />
                </div>
              </div>
            )}

            <div className="alert alert-info">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>You can update these settings later in your user profile.</span>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <h4 className="text-lg font-semibold">You've been enrolled in our Free tier</h4>
            
            <div className="stats shadow w-full">
              <div className="stat">
                <div className="stat-title">Available Scans</div>
                <div className="stat-value text-primary">{userData?.num_requests || 20}</div>
                <div className="stat-desc">Start exploring our service</div>
              </div>
            </div>

            {renderApiKey()}

            <div className="divider">Benefits</div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {[
                { icon: faShieldAlt, text: "Advanced AI-powered URL analysis" },
                { icon: faShieldAlt, text: "Comprehensive security checks" },
                { icon: faBolt, text: "Instant results" }
              ].map((benefit, i) => (
                <div key={i} className="flex items-center gap-3 p-3 bg-base-200 rounded-lg">
                  <FontAwesomeIcon icon={benefit.icon} className="text-secondary" />
                  <span>{benefit.text}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="modal-action mt-8">
          {step === 1 ? (
            <button
              className="btn btn-primary"
              onClick={handleNextStep}
            >
              Next
            </button>
          ) : (
            <>
              <button
                className="btn btn-secondary"
                onClick={handleViewPricing}
              >
                <span className="flex items-center gap-2">
                  View Pricing
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={handleComplete}
              >
                Start Scanning
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstTimeLoginModal;