import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faServer, faEye, faFileAlt, faRobot } from '@fortawesome/free-solid-svg-icons';

const AIAnalysis = ({ scanData }) => {
  if (!scanData.ai_analysis || typeof scanData.ai_analysis !== 'object' || !scanData.ai_analysis.analysis) {
    return (
      <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
        <div className="card-body">
          <h2 className="card-title">
            <FontAwesomeIcon icon={faRobot} className="mr-2" /> AI Analysis
          </h2>
          <div className="alert alert-info">
            <div>
              <h3 className="font-bold">AI Analysis Not Available</h3>
              <div className="text-sm">
                AI analysis was not performed for this URL. This may be due to the site being unavailable, or our system determining that AI analysis was not necessary for this particular URL.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let analysis, classification, riskScore;
  try {
    let parsedAiAnalysis;
    if (typeof scanData.ai_analysis.analysis === 'string') {
      // Handle older format
      parsedAiAnalysis = JSON.parse(scanData.ai_analysis.analysis.replace(/```json|```/g, ''));
    } else {
      // Handle new format
      parsedAiAnalysis = scanData.ai_analysis;
    }
    analysis = parsedAiAnalysis.analysis;
    classification = parsedAiAnalysis.classification;
    riskScore = parsedAiAnalysis.risk_score;
  } catch (error) {
    console.error("Error parsing ai_analysis:", error);
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">
            <FontAwesomeIcon icon={faRobot} className="mr-2" /> AI.nspect
          </h2>
          <div className="alert alert-error">
            <div>
              <h3 className="font-bold">Error in AI Analysis</h3>
              <div className="text-sm">
                There was an error parsing the AI analysis results. Please try again later or contact support if the issue persists.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getAlertStatus = (classification) => {
    const lowerCaseClassification = classification.toLowerCase();
    if (lowerCaseClassification.includes('not malicious')) {
      return 'alert-success';
    } else if (lowerCaseClassification.includes('possibly malicious')) {
      return 'alert-warning';
    } else if (lowerCaseClassification.includes('malicious')) {
      return 'bg-red-200 text-red-800'; // Lighter red background with darker red text
    }
    return 'alert-warning';
  };

  const renderContent = (content) => {
    // Ensure content is a string
    if (typeof content !== 'string') {
      console.warn('renderContent received non-string content:', content);
      return String(content);
    }

    // Remove backticks from the content
    content = content.replace(/`/g, '');
    
    // Split content by URLs
    const parts = content.split(/(https?:\/\/[^\s]+)/g);
    return parts.map((part, index) => {
      if (part.match(/^https?:\/\//)) {
        return (
          <kbd key={index} className="kbd kbd-sm font-mono text-xs px-2 py-1 break-all">
            {part}
          </kbd>
        );
      }
      return part;
    });
  };

  return (
    <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
      <div className="card-body">
        <h2 className="card-title mb-4">
          <FontAwesomeIcon icon={faRobot} className="mr-2" aria-hidden="true" /> AI.nspect
        </h2>
        <div className="space-y-6">
          <div className={`alert ${getAlertStatus(classification)}`}>
            <div>
              <h3 className="font-bold">Classification: {classification}</h3>
              <div className="text-sm break-words">{renderContent(analysis.summary)}</div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Analysis Details</h3>
            <ul className="space-y-4">
              {[
                { icon: faLink, color: "text-blue-500", title: "URL Structure", content: analysis.url_structure },
                { icon: faServer, color: "text-orange-500", title: "Domain and Hosting", content: analysis.domain_and_hosting },
                { icon: faEye, color: "text-green-500", title: "Content and Visuals", content: analysis.content_and_visuals },
                { icon: faFileAlt, color: "text-purple-500", title: "Forms and Data Handling", content: analysis.forms_and_data_handling },
                { icon: faRobot, color: "text-teal-500", title: "Additional Factors", content: analysis.additional_factors }
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <FontAwesomeIcon icon={item.icon} className={`${item.color} mt-1 mr-2 flex-shrink-0`} aria-hidden="true" />
                  <div className="flex-grow">
                    <p className="text-base-content">
                      <strong>{item.title}:</strong>{' '}
                      <span className="break-words">{renderContent(item.content)}</span>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIAnalysis;
