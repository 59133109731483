import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { ThemeContext } from '../utils/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faExternalLinkAlt, 
  faLink, 
  faCheckCircle, 
  faExclamationTriangle, 
  faQuestionCircle,
  faSkull,
  faHeartbeat,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import SearchComponent from './SearchComponent';
import { Helmet } from 'react-helmet-async';
import DashboardLayout from './DashboardLayout';

const ITEMS_PER_PAGE = 20;

const RecentScans = () => {
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [myScans, setMyScans] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [todayScansCount, setTodayScansCount] = useState(0);
  const [highRiskCount, setHighRiskCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const observer = useRef();
  const { session } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [selectedUser, setSelectedUser] = useState(null);

  const selectedTag = searchParams.get('tag');
  const selectedJarm = searchParams.get('jarm');

  // Reset myScans if user logs out
  useEffect(() => {
    if (!session) {
      setMyScans(false);
    }
  }, [session]);

  useEffect(() => {
    const userParam = searchParams.get('user');
    setSelectedUser(userParam);
  }, [searchParams]);

  const lastScanElementRef = useCallback(node => {
    if (loadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreScans();
      }
    });
    if (node) observer.current.observe(node);
  }, [loadingMore, hasMore]);

  const fetchScans = useCallback(async (pageIndex = 0) => {
    setLoadingMore(true);
    const startIndex = pageIndex * ITEMS_PER_PAGE;
    
    let query = supabase
      .from('ripper_details')
      .select(`
        id,
        job_id,
        original_url,
        timestamp,
        final_score,
        alive,
        is_private,
        linked_user,
        type,
        parent_id,
        screenshot_url,
        ai_analysis->risk_score,
        ai_analysis->tags,
        jarm_fingerprint,
        users(name)  // Add this line to join with users table
      `, { count: 'exact' })
      .order('timestamp', { ascending: false });

    if (searchTerm) {
      query = query.ilike('original_url', `%${searchTerm}%`);
    }

    // Updated private filter logic
    if (session) {
      if (isPrivate) {
        // Show only private scans for the current user
        query = query.eq('is_private', true).eq('linked_user', session.user.id);
      } else {
        // Show public scans and private scans for the current user
        query = query.or(`is_private.eq.false,and(is_private.eq.true,linked_user.eq.${session.user.id})`);
      }
    } else {
      // If not logged in, only show public scans
      query = query.eq('is_private', false);
    }

    if (myScans) {
      if (session) {
        query = query.eq('linked_user', session.user.id);
      } else {
        query = query.eq('linked_user', 'no_session');
      }
    }

    if (selectedTag) {
      query = query.filter('ai_analysis->tags', 'cs', `["${selectedTag}"]`);
    }

    if (selectedJarm) {
      query = query.ilike('jarm_fingerprint', `%${selectedJarm}%`);
    }

    if (selectedUser) {
      query = query.eq('linked_user', selectedUser);
    }

    const { data, error, count } = await query;

    if (error) {
      console.error('Error fetching scans:', error);
      setLoading(false);
      setLoadingMore(false);
      return;
    }

    setScans(prevScans => {
      const newScans = pageIndex === 0 ? data : [...prevScans, ...data];
      return newScans.filter((scan, index, self) =>
        index === self.findIndex((t) => t.id === scan.id)
      );
    });
    setTotalCount(count);
    setHasMore(data.length === ITEMS_PER_PAGE && startIndex + data.length < count);
    setPage(pageIndex);
    setLoading(false);
    setLoadingMore(false);
  }, [searchTerm, isPrivate, myScans, session, selectedTag, selectedJarm, selectedUser]);

  useEffect(() => {
    setLoading(true);
    setPage(0);
    fetchScans(0);
  }, [fetchScans]);

  const loadMoreScans = useCallback(() => {
    if (!loadingMore && hasMore) {
      fetchScans(page + 1);
    }
  }, [loadingMore, hasMore, fetchScans, page]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setPage(0);
  };

  const handlePrivateToggle = useCallback((e) => {
    setIsPrivate(e.target.checked);
  }, []);

  const handleMyScansToggle = useCallback((e) => {
    if (!session) {
      // Optionally, show a message that user needs to log in
      alert("Please log in to view your scans");
      return;
    }
    setMyScans(e.target.checked);
  }, [session]);

  const getRiskScore = useCallback((scan) => {
    if (scan.risk_score !== null && scan.risk_score !== undefined) {
      return scan.risk_score;
    }
    return scan.final_score || null;
  }, []);

  const getRiskColor = useCallback((scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'bg-gray-100 text-gray-800';
    if (score > 75) return 'bg-red-100 text-red-800';
    if (score >= 35) return 'bg-yellow-100 text-yellow-800';
    return 'bg-green-100 text-green-800';
  }, [getRiskScore]);

  const getRiskText = useCallback((scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'N/A';
    if (score > 75) return 'High';
    if (score >= 35) return 'Moderate';
    return 'Low';
  }, [getRiskScore]);

  const getStatusIcon = useCallback((scan) => {
    if (scan.alive === 'alive') return faHeartbeat;
    if (scan.alive === 'not alive') return faSkull;
    return faQuestionCircle;
  }, []);

  const getStatusColor = useCallback((scan) => {
    if (scan.alive === 'alive') return 'text-green-600 dark:text-green-400';
    if (scan.alive === 'not alive') return 'text-red-600 dark:text-red-400';
    return 'text-gray-600 dark:text-gray-400';
  }, []);

  const getStatusTooltip = useCallback((scan) => {
    if (scan.alive === 'alive') return 'WAS_LIVE';
    if (scan.alive === 'not alive') return 'WAS_DEAD';
    return 'UNKNOWN';
  }, []);

  const truncateUrl = useCallback((url, maxLength = 30) => {
    if (url.length <= maxLength) return url;
    return `${url.slice(0, maxLength)}...`;
  }, []);

  const getResizedImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return null;
    
    // The originalUrl should now be in the format:
    // https://imagedelivery.net/<ACCOUNT_HASH>/<IMAGE_ID>
    
    // Append the 'recentscans' variant
    return `${originalUrl}/fullsize`;
  }, []);

  const handleParentClick = useCallback(async (e, parentId) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!parentId) {
      console.error('Parent ID is missing');
      alert('Unable to find parent scan: Parent ID is missing');
      return;
    }

    try {
      // First, fetch the job_id of the parent scan using its id
      const { data, error } = await supabase
        .from('ripper_details')
        .select('job_id')
        .eq('id', parentId)
        .single();

      if (error) throw error;

      if (!data || !data.job_id) {
        throw new Error('Parent scan not found');
      }

      const parentJobId = data.job_id;

      // Log the navigation attempt
      console.log(`Attempting to navigate to /results/${parentJobId}`);

      // Navigate to the parent scan's results page
      navigate(`/results/${parentJobId}`);
    } catch (error) {
      console.error('Error fetching parent scan:', error);
      alert(`Unable to find parent scan. Error: ${error.message}`);
    }
  }, [navigate]);

  // New function to fetch today's scans count
  const fetchTodayScansCount = useCallback(async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const { count, error } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .gte('created_at', today.toISOString());

    if (error) {
      console.error('Error fetching today\'s scans count:', error);
    } else {
      setTodayScansCount(count);
    }
  }, []);

  // New function to fetch high-risk sites count
  const fetchHighRiskCount = useCallback(async () => {
    const { count, error } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .or('final_score.gt.75,ai_analysis->risk_score.gt.75');

    if (error) {
      console.error('Error fetching high-risk sites count:', error);
    } else {
      setHighRiskCount(count);
    }
  }, []);

  useEffect(() => {
    fetchTodayScansCount();
    fetchHighRiskCount();
  }, [fetchTodayScansCount, fetchHighRiskCount]);

  const clearTagFilter = () => {
    searchParams.delete('tag');
    setSearchParams(searchParams);
  };

  const clearJarmFilter = () => {
    searchParams.delete('jarm');
    setSearchParams(searchParams);
  };

  const clearUserFilter = () => {
    searchParams.delete('user');
    setSearchParams(searchParams);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>QSI - Recent Scans</title>
        <meta name="description" content="View recent scans performed by our AI-powered URL scanner." />
        <meta name="keywords" content="URL scanner, recent scans, website security, threat detection" />
      </Helmet>
      <div className={`min-h-screen bg-base-100 ${theme}`}>
        <main className="container mx-auto py-4 sm:py-10 px-2 sm:px-6 lg:px-8">
          <h1 className="text-xl sm:text-3xl font-bold text-center mb-3 sm:mb-6">
            Recent Scans
          </h1>
          <div className="flex justify-center mb-4 sm:mb-8 overflow-x-auto">
            <div className="stats stats-horizontal shadow">
              <div className="stat place-items-center p-1 sm:p-4">
                <div className="stat-title text-[10px] sm:text-sm">Total Scans</div>
                <div className="stat-value text-primary text-lg sm:text-3xl">{totalCount.toLocaleString()}</div>
                <div className="stat-desc text-[8px] sm:text-xs">AI-powered analysis</div>
              </div>
              <div className="stat place-items-center p-1 sm:p-4">
                <div className="stat-title text-[10px] sm:text-sm">Scans Today</div>
                <div className="stat-value text-secondary text-lg sm:text-3xl">{todayScansCount.toLocaleString()}</div>
                <div className="stat-desc text-[8px] sm:text-xs">Since midnight</div>
              </div>
              <div className="stat place-items-center p-1 sm:p-4">
                <div className="stat-title text-[10px] sm:text-sm">High Risk Sites</div>
                <div className="stat-value text-error text-lg sm:text-3xl">{highRiskCount.toLocaleString()}</div>
                <div className="stat-desc text-[8px] sm:text-xs">Score gte 75</div>
              </div>
            </div>
          </div>
          <SearchComponent onSearch={handleSearch} />
          <div className="flex items-center mt-4 space-x-4">
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text mr-2">Private</span>
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text mr-2">My Scans</span>
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={myScans}
                  onChange={(e) => setMyScans(e.target.checked)}
                  disabled={!session}
                />
              </label>
            </div>
            {selectedTag && (
              <div className="flex items-center">
                <span className="mr-2">Filtered by tag:</span>
                <span className="badge badge-primary">{selectedTag}</span>
                <button
                  onClick={clearTagFilter}
                  className="btn btn-ghost btn-xs ml-2"
                  aria-label="Clear tag filter"
                >
                  ✕
                </button>
              </div>
            )}
            {selectedJarm && (
              <div className="flex items-center">
                <span className="mr-2">Filtered by JARM:</span>
                <span className="badge badge-primary">{selectedJarm}</span>
                <button
                  onClick={clearJarmFilter}
                  className="btn btn-ghost btn-xs ml-2"
                  aria-label="Clear JARM filter"
                >
                  ✕
                </button>
              </div>
            )}
            {selectedUser && (
              <div className="flex items-center">
                <span className="mr-2">Scans by user:</span>
                <span className="badge badge-primary">{selectedUser}</span>
                <button
                  onClick={clearUserFilter}
                  className="btn btn-ghost btn-xs ml-2"
                  aria-label="Clear user filter"
                >
                  ✕
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6" role="list">
            {scans.map((scan, index) => (
              <div 
                key={scan.id}
                className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200 cursor-pointer"
                onClick={() => navigate(`/results/${scan.job_id}`)}
                ref={index === scans.length - 1 ? lastScanElementRef : null}
                role="listitem"
              >
                <div className="card-body p-4">
                  <h3 className="card-title text-base-content text-sm mb-2 flex items-start">
                    <div 
                      className="tooltip tooltip-bottom before:max-w-xs before:content-[attr(data-tip)] before:break-all before:whitespace-normal" 
                      data-tip={scan.original_url}
                      style={{ '--tooltip-tail': '6px' }}
                    >
                      <span className="truncate block max-w-[calc(100%-1.5rem)]">
                        {truncateUrl(scan.original_url, 30)}
                      </span>
                    </div>
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1 text-xs flex-shrink-0" aria-hidden="true" />
                  </h3>
                  <p className="text-sm text-base-content opacity-70">
                    Scanned on: {new Date(scan.timestamp).toLocaleString()}
                  </p>
                  {scan.users?.name && (
                    <p className="text-sm text-base-content opacity-70">
                      Submitted by: {scan.users.name}
                    </p>
                  )}
                  <div className="flex flex-wrap gap-2 mt-2" role="group" aria-label="Scan details">
                    <span className={`badge ${getRiskColor(scan)} text-2xs sm:text-xs whitespace-nowrap px-2 sm:px-3 py-1 sm:py-2`}>
                      {getRiskText(scan)}
                    </span>
                    <span 
                      className={`inline-flex items-center justify-center w-5 h-5 sm:w-6 sm:h-6 rounded-full ${getStatusColor(scan)}`}
                      title={getStatusTooltip(scan)}
                    >
                      <FontAwesomeIcon icon={getStatusIcon(scan)} className="text-xs sm:text-sm" />
                    </span>
                    {scan.type === 'child' && scan.parent_id && (
                      <button
                        onClick={(e) => handleParentClick(e, scan.parent_id)}
                        className="btn btn-xs btn-link"
                        aria-label="View parent scan"
                      >
                        <FontAwesomeIcon icon={faLink} aria-hidden="true" /> PARENT
                      </button>
                    )}
                  </div>
                </div>
                {scan.screenshot_url ? (
                  <figure>
                    <img
                      src={getResizedImageUrl(scan.screenshot_url)}
                      alt={`Screenshot of ${truncateUrl(scan.original_url)}`}
                      className="w-full h-32 object-cover"
                      loading="lazy"
                    />
                  </figure>
                ) : (
                  <div className="w-full h-32 bg-base-200 flex items-center justify-center">
                    <span className="text-base-content opacity-50 text-sm">No preview available</span>
                  </div>
                )}
                {scan.ai_analysis && scan.ai_analysis.tags && (
                  <div className="card-body p-2">
                    <div className="flex flex-wrap gap-1">
                      {scan.ai_analysis.tags.map((tag, tagIndex) => (
                        <span 
                          key={tagIndex}
                          className="badge badge-outline badge-sm cursor-pointer hover:bg-base-200"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/recent-scans?tag=${encodeURIComponent(tag)}`);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {(loading || loadingMore) && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6" aria-live="polite" aria-busy="true">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="card bg-base-200 h-64 animate-pulse" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ))}
            </div>
          )}
        </main>
      </div>
    </DashboardLayout>
  );
};

export default RecentScans;
