import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faRobot, faBolt, faCode, faRss, faGlobe, faEye, faClipboardCheck, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

const FeatureItem = ({ icon, title, description }) => (
  <div className="relative" role="listitem">
    <dt>
      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-primary-content">
        <FontAwesomeIcon icon={icon} className="h-6 w-6" aria-hidden="true" />
      </div>
      <p className="ml-16 text-lg font-medium text-base-content">{title}</p>
    </dt>
    <dd className="mt-2 ml-16 text-base text-base-content opacity-70">
      {description}
    </dd>
  </div>
);

const HowItWorks = () => {
  return (
    <div className="flex flex-col min-h-screen bg-base-100">
      <Helmet>
        <title>QSI - How It Works</title>
        <meta name="description" content="Discover how our AI-driven phishing detection solution works to protect you from online threats." />
      </Helmet>
      <Header />

      <main className="flex-grow">
        <div className="bg-base-100 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-primary font-semibold tracking-wide uppercase">How It Works</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-base-content sm:text-4xl">
                Discover the power of our AI-driven phishing detection solution
              </p>
            </div>

            <div className="mt-10">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="md:w-1/2">
                  <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10" role="list">
                    <FeatureItem
                      icon={faShieldAlt}
                      title="30+ Heuristics"
                      description="Our solution employs over 30 heuristic methods to analyze URLs for potential threats."
                    />
                    <FeatureItem
                      icon={faRobot}
                      title="AI-Powered Analysis"
                      description="Advanced machine learning algorithms provide accurate and reliable results."
                    />
                    <FeatureItem
                      icon={faBolt}
                      title="Instant Results"
                      description="Get immediate feedback on the safety of any URL."
                    />
                    <FeatureItem
                      icon={faCode}
                      title="Native API"
                      description="Integrate our powerful phishing detection capabilities into your applications."
                    />
                    <FeatureItem
                      icon={faRss}
                      title="Notifications"
                      description="Receive real-time notifications and updates."
                    />
                  </dl>
                </div>

                <div className="md:w-1/2 mt-10 md:mt-0">
                  <img
                    src="https://nx.qsi.net/storage/v1/object/public/assets/results.png"
                    alt="Result Screenshot"
                    className="rounded-lg shadow-lg max-w-full h-auto"
                  />
                </div>
              </div>
            </div>

            <div className="divider my-20"></div>

            <div className="mt-20">
              <div className="lg:text-center mb-10">
                <h2 className="text-base text-primary font-semibold tracking-wide uppercase">Introducing AI.nspect</h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-base-content sm:text-4xl">
                  Deep dive into any URL with the power of AI
                </p>
              </div>

              <div className="flex flex-col md:flex-row justify-between items-start">
                <div className="md:w-1/2">
                  <img
                    src="https://nx.qsi.net/storage/v1/object/public/assets/a_inspector.png"
                    alt="AI Inspector"
                    className="rounded-lg shadow-lg max-w-full h-auto"
                  />
                </div>

                <div className="md:w-1/2 mt-10 md:mt-0 md:ml-10">
                  <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10" role="list">
                    <FeatureItem
                      icon={faGlobe}
                      title="URL Structure"
                      description="Analyze the structure and components of the URL for potential threats."
                    />
                    <FeatureItem
                      icon={faEye}
                      title="Content and Visuals"
                      description="Inspect the content and visual elements of the website for suspicious patterns."
                    />
                    <FeatureItem
                      icon={faClipboardCheck}
                      title="Forms and Data Handling"
                      description="Evaluate the forms and data handling practices for potential security risks."
                    />
                    <FeatureItem
                      icon={faCodeBranch}
                      title="HTML Analysis"
                      description="Analyze the HTML code for deobfuscated content, suspicious scripts, and potential threats."
                    />
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default HowItWorks;