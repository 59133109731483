import React, { useContext, useState } from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import { AuthContext } from '../utils/AuthContext';
import UserDetailsSection from './UserDetailsSection';
import APIKeySection from './APIKeySection';
import WebhookSection from './WebhookSection';
import SubscriptionManager from './SubscriptionManager';
import ReleaseNotes from './ReleaseNotes';

const DashboardLayout = ({ children }) => {
  const { session, isLoading, userData } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Check if the current route is public
  const isPublicRoute = ['/recent-scans', '/results'].some(route => location.pathname.startsWith(route));

  // Determine if the user is on a free tier
  const isFreeTier = userData?.plan_id === 'fd5938fb-a785-4330-9e50-29bec31c6533';
  const isBasicOrHigher = ['ad570897-fec6-4063-a005-460c41dd5300', '3ab56f81-3f40-460c-a85e-cbcf94cf675d', 'd43da4a9-d701-447c-959f-cd1affd2561e'].includes(userData?.plan_id);
  const isEnterprise = userData?.plan_id === 'd43da4a9-d701-447c-959f-cd1affd2561e';

  // Add this check before the renderContent function
  if (!isLoading && !session && location.pathname.startsWith('/dashboard')) {
    return <Navigate to="/login" replace />;
  }

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    // Handle settings routes
    if (location.pathname.startsWith('/dashboard/settings/')) {
      if (!session) {
        return <Navigate to="/login" replace />;
      }
      
      switch (location.pathname) {
        case '/dashboard/settings/user-details':
          return <UserDetailsSection />;
        case '/dashboard/settings/api-key':
          return <APIKeySection userData={userData} isDisabled={!isBasicOrHigher} />;
        case '/dashboard/settings/webhooks':
          return <WebhookSection userData={userData} isDisabled={!isEnterprise} />;
        case '/dashboard/settings/subscription':
          return <SubscriptionManager />;
        default:
          return null;
      }
    }

    // Handle other routes
    if (!session && !isPublicRoute) {
      return <Navigate to="/login" replace />;
    }

    return children || <Outlet />;
  };

  // Render the layout content
  return (
    <div className="flex h-screen bg-base-100 overflow-hidden">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex flex-col flex-1 overflow-hidden">
        <DashboardHeader toggleSidebar={toggleSidebar} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-base-100">
          <div className="p-2 sm:p-4">
            {renderContent()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
