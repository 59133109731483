import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../utils/AuthContext';
import { motion } from 'framer-motion';
import { supabase } from '../utils/supabaseClient';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationCircle, faClock } from '@fortawesome/free-solid-svg-icons';
import ConfirmPlanChangeModal from './ConfirmPlanChangeModal';

const MotionDiv = motion.div;

const PricingTier = React.memo(({ name, price, features, negatives, ctaText, onCtaClick, isComingSoon, isCurrentPlan, isLoggedIn, isDowngrade }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonText = () => {
    if (isLoggedIn && !isComingSoon) {
      return isDowngrade ? "Downgrade" : "Upgrade";
    }
    return ctaText;
  };

  const buttonClass = () => {
    if (isComingSoon) return 'btn-disabled';
    if (isDowngrade) return 'btn-warning';
    return 'btn-primary'; // Always use btn-primary for upgrades
  };

  return (
    <MotionDiv
      className={`card ${isCurrentPlan ? 'bg-primary text-primary-content' : 'bg-base-100'} shadow-xl`}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="card-body">
        <h2 className={`card-title ${isCurrentPlan ? 'text-primary-content' : ''}`}>{name}</h2>
        <p className={`text-3xl font-bold ${isCurrentPlan ? 'text-primary-content' : ''}`}>{price}</p>
        <ul className="space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <FontAwesomeIcon icon={faCheck} className={`mr-2 ${isCurrentPlan ? 'text-primary-content' : 'text-success'}`} />
              <span className={isCurrentPlan ? 'text-primary-content' : ''}>{feature}</span>
            </li>
          ))}
          {negatives && negatives.map((negative, index) => (
            <li key={`neg-${index}`} className={`flex items-center ${isCurrentPlan ? 'text-primary-content opacity-80' : 'text-base-content opacity-70'}`}>
              <FontAwesomeIcon icon={faTimes} className={`mr-2 ${isCurrentPlan ? 'text-primary-content' : 'text-error'}`} />
              {negative}
            </li>
          ))}
        </ul>
        <div className="card-actions justify-end mt-4">
          {isCurrentPlan ? (
            <div className="badge badge-secondary badge-lg">Current Plan</div>
          ) : (
            <button 
              className={`btn ${buttonClass()}`}
              onClick={onCtaClick}
              disabled={isComingSoon}
            >
              {buttonText()}
            </button>
          )}
        </div>
      </div>
    </MotionDiv>
  );
});

const PricingPage = () => {
  const { session } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [pendingPlanChange, setPendingPlanChange] = useState(null); // Add this
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    plan: null,
    type: null // 'upgrade', 'downgrade', or 'free'
  });

  useEffect(() => {
    fetchPlans();
    if (session) {
      fetchUserPlan();
    }
  }, [session]);

  const fetchPlans = async () => {
    const { data, error } = await supabase
      .from('plans')
      .select('id, name, price, interval, stripe_price_id, features, negatives, scan_limit')
      .order('price', { ascending: true });
    if (error) {
      console.error('Error fetching plans:', error);
    } else {
      setPlans(data);
    }
  };

  const fetchUserPlan = async () => {
    if (session) {
      const { data, error } = await supabase
        .from('users')
        .select('plan_id')
        .eq('id', session.user.id)
        .single();

      if (error) {
        console.error('Error fetching user plan:', error);
      } else {
        setUserPlan(data.plan_id);
        await fetchCurrentPlanDetails(data.plan_id);
      }
    }
  };

  const fetchCurrentPlanDetails = async (planId) => {
    const { data: planData, error: planError } = await supabase
      .from('plans')
      .select('id, name, price, interval, scan_limit')
      .eq('id', planId)
      .single();

    if (planError) {
      console.error('Error fetching plan details:', planError);
      return;
    }

    // Get subscription details if available
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('stripe_customer_id')
      .eq('id', session.user.id)
      .single();

    if (!userError && userData.stripe_customer_id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subscription-details`, {
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
        
        if (response.ok) {
          const subData = await response.json();
          setCurrentPlanDetails({
            ...planData,
            subscription_start: subData.current_period_start * 1000,
            subscription_end: subData.current_period_end * 1000
          });
          return;
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      }
    }

    setCurrentPlanDetails(planData);
  };

  const handlePlanClick = async (plan) => {
    if (!session) {
      navigate('/signup');
      return;
    }

    // Open confirmation modal for any plan change
    setConfirmModal({
      isOpen: true,
      plan,
      type: plan.price === 0 ? 'free' : isPlanDowngrade(plan.price) ? 'downgrade' : 'upgrade'
    });
  };

  const handleContactSalesClick = () => {
    window.location.href = "mailto:dan@qsi.net?subject=Interested in Custom Solution for QSI";
  };

  const handleCheckoutSuccess = async () => {
    setIsCheckoutModalOpen(false);
    
    // Refresh user plan information
    await fetchUserPlan();
    
    // toast({
    //   title: "Subscription Updated",
    //   description: `You've successfully subscribed to the ${selectedPlan.name} plan.`,
    //   status: "success",
    //   duration: 5000,
    //   isClosable: true,
    // });
  };

  const isPlanDowngrade = (planPrice) => {
    if (!currentPlanDetails) return false;
    if (currentPlanDetails.price === 0) return false; // Can't downgrade from free plan
    return planPrice < currentPlanDetails.price;
  };

  const handlePlanDowngrade = async (plan) => {
    if (!session) return;
    
    try {
      console.log('Initiating plan downgrade to:', plan.name);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          priceId: plan.stripe_price_id,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update subscription');
      }
      
      console.log('Subscription update response:', data);

      // Update the pending plan change state
      setPendingPlanChange({
        planName: plan.name,
        effectiveDate: new Date(data.effectiveDate * 1000).toLocaleDateString()
      });

      // Show success message
      alert(`Your plan will be updated on ${new Date(data.effectiveDate * 1000).toLocaleDateString()} at the end of your current billing period.`);

    } catch (error) {
      console.error('Error updating subscription:', error);
      alert(`Failed to update subscription: ${error.message}`);
    }
  };

  // Add useEffect to fetch pending plan changes when component mounts
  useEffect(() => {
    const fetchPendingChanges = async () => {
      if (session) {
        try {
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('stripe_customer_id')
            .eq('id', session.user.id)
            .single();

          if (userError || !userData.stripe_customer_id) return;

          // Fetch subscription details from your backend
          const response = await fetch(`${process.env.REACT_APP_API_URL}/subscription-status`, {
            headers: {
              'Authorization': `Bearer ${session.access_token}`
            }
          });

          if (response.ok) {
            const data = await response.json();
            if (data.pendingUpdate) {
              setPendingPlanChange({
                planName: data.pendingUpdate.planName,
                effectiveDate: new Date(data.pendingUpdate.effectiveDate * 1000).toLocaleDateString()
              });
            }
          }
        } catch (error) {
          console.error('Error fetching pending changes:', error);
        }
      }
    };

    fetchPendingChanges();
  }, [session]);

  const handleConfirmPlanChange = async () => {
    const { plan, type } = confirmModal;
    setConfirmModal({ isOpen: false, plan: null, type: null });

    try {
      if (type === 'upgrade') {
        const priceId = plan.stripe_price_id;
        
        if (!priceId) {
          throw new Error('Invalid plan selected');
        }

        // Load Stripe first - add this line
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        if (!stripe) {
          throw new Error('Failed to load Stripe');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            priceId: priceId,
            successUrl: `${window.location.origin}/success`,
            cancelUrl: `${window.location.origin}/pricing`,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create checkout session');
        }

        const { sessionId } = await response.json();
        
        // Update redirect logic
        const result = await stripe.redirectToCheckout({ sessionId });
        
        if (result.error) {
          throw new Error(result.error.message);
        }
      } else if (type === 'downgrade') {
        await handlePlanDowngrade(plan);
      } else if (type === 'free') {
        // Call endpoint to cancel subscription
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to cancel subscription');
        }

        const data = await response.json();
        
        // Update UI to show when subscription will end
        setPendingPlanChange({
          planName: 'Free',
          effectiveDate: new Date(data.cancelAt * 1000).toLocaleDateString()
        });
      }
    } catch (error) {
      console.error('Error handling plan change:', error);
      alert(`Failed to change plan: ${error.message}`);
    }
  };

  // Add this useEffect to fetch subscription details on page load
  useEffect(() => {
    if (session) {
      fetchSubscriptionDetails();
    }
  }, [session]);

  // Add function to fetch subscription details
  const fetchSubscriptionDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscription-details`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch subscription details');
      }

      const data = await response.json();
      
      // Check for pending changes
      if (data.cancel_at_period_end || data.cancel_at) {
        setPendingPlanChange({
          planName: 'Free',
          effectiveDate: new Date(data.current_period_end * 1000).toLocaleDateString()
        });
      }
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  };

  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>QSI - Pricing</title>
        <meta name="description" content="Choose the perfect plan for your URL scanning needs." />
      </Helmet>
      <Header openLoginModalExternal={openLoginModal} />
      
      <main className="container mx-auto py-16 px-4">
        <div className="text-center space-y-8">
          <div>
            <h1 className="text-4xl font-bold mb-4">Choose Your Plan</h1>
            <p className="text-xl text-base-content opacity-70 max-w-2xl mx-auto">
              Select the perfect plan for your needs. All plans include our advanced AI-powered URL analysis.
            </p>
          </div>

          {session && currentPlanDetails && (
            <div className="w-full flex justify-center px-4"> {/* Added container for centering */}
              <div className="alert bg-base-200 shadow-lg p-6 max-w-4xl w-full"> {/* Added max-width */}
                <div className="flex flex-col md:flex-row w-full gap-8">
                  {/* Current Plan Info */}
                  <div className="flex-1 min-w-[250px]">
                    <div className="flex items-start gap-4">
                      <FontAwesomeIcon 
                        icon={faExclamationCircle} 
                        className="text-primary text-xl mt-1 shrink-0" // Added shrink-0
                      />
                      <div className="space-y-3 w-full"> {/* Added w-full */}
                        <div>
                          <h3 className="text-lg font-bold text-primary mb-2">
                            Current Plan: {currentPlanDetails.name}
                          </h3>
                          <div className="space-y-2">
                            <p className="flex items-center gap-2 text-base">
                              <span className="opacity-75 min-w-[4rem]">Scans:</span> {/* Added min-width to align labels */}
                              <span className="font-medium">
                                {currentPlanDetails.scan_limit} per {currentPlanDetails.interval}
                              </span>
                            </p>
                            {currentPlanDetails.subscription_start && (
                              <p className="flex items-center gap-2 text-base">
                                <span className="opacity-75 min-w-[4rem]">Started:</span>
                                <span className="font-medium">
                                  {new Date(currentPlanDetails.subscription_start).toLocaleDateString()}
                                </span>
                              </p>
                            )}
                            {currentPlanDetails.price > 0 && (
                              <p className="flex items-center gap-2 text-base">
                                <span className="opacity-75 min-w-[4rem]">Billing:</span>
                                <span className="font-medium">
                                  ${currentPlanDetails.price}/{currentPlanDetails.interval}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Divider for desktop */}
                  {pendingPlanChange && (
                    <div className="hidden md:block w-px bg-base-300 self-stretch mx-4"></div>
                  )}

                  {/* Pending Changes */}
                  {pendingPlanChange && (
                    <div className="flex-1 min-w-[250px] md:pl-4"> {/* Adjusted padding */}
                      <div className="flex items-start gap-4">
                        <FontAwesomeIcon 
                          icon={faClock} 
                          className="text-warning text-xl mt-1 shrink-0" // Added shrink-0
                        />
                        <div className="space-y-3 w-full"> {/* Added w-full */}
                          <div>
                            <h3 className="text-lg font-bold text-warning mb-2">
                              Pending Change
                            </h3>
                            <div className="space-y-2">
                              <p className="flex items-center gap-2 text-base">
                                <span className="opacity-75 min-w-[5.5rem]">New Plan:</span> {/* Added min-width to align labels */}
                                <span className="font-medium">
                                  {pendingPlanChange.planName}
                                </span>
                              </p>
                              <p className="flex items-center gap-2 text-base">
                                <span className="opacity-75 min-w-[5.5rem]">Effective:</span>
                                <span className="font-medium">
                                  {pendingPlanChange.effectiveDate}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.filter(plan => plan.price > 0).map((plan) => (
              <PricingTier 
                key={plan.id}
                name={plan.name}
                price={`$${plan.price}/${plan.interval}`}
                features={plan.features}
                negatives={plan.negatives}
                ctaText={session ? 'Upgrade' : 'Sign Up'}
                onCtaClick={() => handlePlanClick(plan)}
                isComingSoon={false}
                isCurrentPlan={userPlan === plan.id}
                isLoggedIn={!!session}
                isDowngrade={isPlanDowngrade(plan.price)}
              />
            ))}
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
            {plans.filter(plan => plan.price === 0).map((plan) => (
              <PricingTier 
                key={plan.id}
                name={plan.name}
                price="Free"
                features={plan.features}
                negatives={plan.negatives}
                ctaText={session ? 'Switch to Free' : 'Sign Up For Free'}
                onCtaClick={() => handlePlanClick(plan)} // Updated to use handlePlanClick
                isComingSoon={false}
                isCurrentPlan={userPlan === plan.id}
                isLoggedIn={!!session}
                isDowngrade={currentPlanDetails && currentPlanDetails.price > 0}
              />
            ))}
            <MotionDiv
              className="card bg-base-100 shadow-xl"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="card-body">
                <h2 className="card-title">Custom Solution</h2>
                <p className="text-3xl font-bold">Contact Us</p>
                <ul className="space-y-2">
                  {[
                    'Customizable scan volume',
                    'Dedicated scanning environment',
                    'API access for automation',
                    'Full reports with AI summaries',
                    'Malicious/Safe decision',
                    'Webhook access for SIEM/SOAR integration',
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-success mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className="card-actions justify-end mt-4">
                  <button 
                    className="btn btn-primary"
                    onClick={handleContactSalesClick}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </MotionDiv>
          </div>
        </div>
      </main>

      <Footer />
      <ConfirmPlanChangeModal
        isOpen={confirmModal.isOpen}
        onClose={() => setConfirmModal({ isOpen: false, plan: null, type: null })}
        onConfirm={handleConfirmPlanChange}
        currentPlan={currentPlanDetails?.name}
        newPlan={confirmModal.plan?.name}
        effectiveDate={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString()} // Example date
        isDowngrade={confirmModal.type === 'downgrade' || confirmModal.type === 'free'}
      />
    </div>
  );
};

export default PricingPage;
