// Create new file: utils/validateUsername.js
import { supabase } from './supabaseClient';

export const validateUsername = async (name, userId) => {
  if (name.length < 3) {
    return "Name must be at least 3 characters long";
  }

  if (name.length > 50) {
    return "Name must be less than 50 characters";
  }

  if (!/^[a-zA-Z0-9\s-_]+$/.test(name)) {
    return "Name can only contain letters, numbers, spaces, hyphens and underscores";
  }

  const { data: protectedName } = await supabase
    .from('protected_usernames')
    .select('username')
    .ilike('username', name.toLowerCase())
    .single();

  if (protectedName) {
    return "This name is not available";
  }

  const { data: existingUser } = await supabase
    .from('users')
    .select('id')
    .neq('id', userId)
    .ilike('name', name.toLowerCase())
    .single();

  if (existingUser) {
    return "This name is already taken by another user";
  }

  return null;
};