import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faList, faChevronDown, faChevronUp, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FormDataDisplay from './FormDataDisplay';

// Register the HTML language
SyntaxHighlighter.registerLanguage('html', html);

const RawHTML = ({ rawHtml, formDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [parsedFormDetails, setParsedFormDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('formData');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    if (formDetails) {
      try {
        const parsed = JSON.parse(formDetails);
        setParsedFormDetails(parsed);
      } catch (error) {
        console.error('Error parsing form details:', error);
        setParsedFormDetails([]);
      }
    } else {
      setParsedFormDetails([]);
    }
  }, [formDetails]);

  const hasFormData = parsedFormDetails.length > 0;

  const renderContent = () => {
    if (!hasFormData || activeTab === 'rawHtml') {
      return (
        <div className="relative overflow-hidden" style={{ maxHeight: isExpanded ? 'none' : '300px' }}>
          <SyntaxHighlighter 
            language="html"
            style={atomOneDark}
            customStyle={{
              margin: 0,
              padding: '1em',
              backgroundColor: 'transparent',
            }}
          >
            {rawHtml}
          </SyntaxHighlighter>
          {!isExpanded && (
            <div 
              className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-base-100 to-transparent"
            />
          )}
        </div>
      );
    } else {
      return <FormDataDisplay formDetails={parsedFormDetails} />;
    }
  };

  if (isMobile) {
    return (
      <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
        <div className="card-body">
          <div className="text-center py-4">
            <FontAwesomeIcon icon={faMobileAlt} className="text-4xl mb-2" />
            <p>HTML content and form data are not available on mobile devices.</p>
            <p>Please view on a larger screen for the full experience.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
      <div className="card-body">
        <h2 className="card-title text-base-content mb-4">
          <FontAwesomeIcon icon={faFileCode} className="mr-2" /> 
          {hasFormData ? 'HTML Content & Form Data' : 'HTML Content'}
        </h2>
        
        {hasFormData && (
          <div className="tabs tabs-boxed mb-4">
            <a 
              className={`tab ${activeTab === 'formData' ? 'tab-active' : ''}`}
              onClick={() => setActiveTab('formData')}
            >
              <FontAwesomeIcon icon={faList} className="mr-2" />
              Form Data ({parsedFormDetails.length})
            </a>
            <a 
              className={`tab ${activeTab === 'rawHtml' ? 'tab-active' : ''}`}
              onClick={() => setActiveTab('rawHtml')}
            >
              <FontAwesomeIcon icon={faFileCode} className="mr-2" />
              Raw HTML
            </a>
          </div>
        )}
        
        {renderContent()}
        
        {(!hasFormData || activeTab === 'rawHtml') && (
          <div className="card-actions justify-end mt-4">
            <button 
              className="btn btn-primary btn-sm"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <>
                  <FontAwesomeIcon icon={faChevronUp} className="mr-2" />
                  Show Less
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                  Show More
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RawHTML;
