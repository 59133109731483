import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { AuthProvider, AuthContext } from './utils/AuthContext';
import ResultsPage from './components/ResultsPage';
import RecentScans from './components/RecentScans';
import ProtectedRoute from './components/ProtectedRoute';
import NotFoundPage from './components/NotFoundPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import HowItWorks from './components/HowItWorks';
import TermsOfServicePage from './components/TermsOfServicePage';
import PricingPage from './components/PricingPage';
import FirstTimeLoginModal from './components/FirstTimeLoginModal';
import { supabase } from './utils/supabaseClient';
import { ThemeProvider, ThemeContext } from './utils/ThemeContext';
import AIURLScan from './components/AIURLScan';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup'; // Add this import
import UserDetailsSection from './components/UserDetailsSection';
import DashboardLayout from './components/DashboardLayout';
import APIKeySection from './components/APIKeySection';
import WebhookSection from './components/WebhookSection';
import SubscriptionManager from './components/SubscriptionManager';
import ReleaseNotes from './components/ReleaseNotes';

// New component to handle routes and FirstTimeLoginModal
const AppRoutes = () => {
  const location = useLocation();
  const showErrorInfo = new URLSearchParams(location.search).get('showErrorInfo') === 'true';
  const { isFirstLogin, setIsFirstLogin, user, userData, setUserData } = useContext(AuthContext);
  const [isFirstTimeLoginModalOpen, setIsFirstTimeLoginModalOpen] = useState(false);

  useEffect(() => {
    if (user && userData && isFirstLogin) {
      setIsFirstTimeLoginModalOpen(true);
    }
  }, [user, userData, isFirstLogin]);

  const handleFirstLoginComplete = async (name, avatarUrl) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ 
          name: name,
          avatar_url: avatarUrl,
          first_login: false 
        })
        .eq('id', user.id);

      if (error) throw error;

      setIsFirstLogin(false);
      setUserData(prevData => ({...prevData, name, avatar_url: avatarUrl, first_login: false}));
      setIsFirstTimeLoginModalOpen(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} /> {/* Add this new route */}
        <Route path="/aiurl-scan" element={<AIURLScan />} />
        <Route path="/results/:jobId" element={<ResultsPage showErrorInfo={showErrorInfo} />} />
        <Route path="/recent-scans" element={<RecentScans />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="settings">
            <Route path="user-details" element={<UserDetailsSection />} />
            <Route path="api-key" element={<APIKeySection />} />
            <Route path="webhooks" element={<WebhookSection />} />
            <Route path="subscription" element={<SubscriptionManager />} />
          </Route>
          <Route path="release-notes" element={<ReleaseNotes />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <FirstTimeLoginModal
        isOpen={isFirstTimeLoginModalOpen}
        onClose={() => setIsFirstTimeLoginModalOpen(false)}
        onComplete={handleFirstLoginComplete}
        userData={userData}
      />
    </>
  );
};

const ThemedApp = () => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <ThemedApp />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
