import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

const WebhookSection = ({ userData, isDisabled }) => {
  const [webhooks, setWebhooks] = useState([]);
  const [newWebhookUrl, setNewWebhookUrl] = useState('');
  const [newWebhookHeaders, setNewWebhookHeaders] = useState([{ key: '', value: '' }]);

  const sanitizeInput = (input) => {
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    const doc = new DOMParser().parseFromString(sanitized, 'text/html');
    return doc.body.textContent || "";
  };

  useEffect(() => {
    fetchWebhooks();
  }, [userData]);

  const fetchWebhooks = async () => {
    if (userData) {
      const { data, error } = await supabase
        .from('user_webhooks')
        .select('id, url, headers')
        .eq('user_id', userData.id);

      if (error) {
        console.error('Error fetching webhooks:', error);
      } else {
        setWebhooks(data);
      }
    }
  };

  const handleAddHeader = () => {
    if (newWebhookHeaders.length < 5) {
      setNewWebhookHeaders([...newWebhookHeaders, { key: '', value: '' }]);
    }
  };

  const handleRemoveHeader = (index) => {
    const updatedHeaders = newWebhookHeaders.filter((_, i) => i !== index);
    setNewWebhookHeaders(updatedHeaders);
  };

  const handleHeaderChange = (index, field, value) => {
    const updatedHeaders = newWebhookHeaders.map((header, i) => 
      i === index ? { ...header, [field]: value } : header
    );
    setNewWebhookHeaders(updatedHeaders);
  };

  const handleAddWebhook = async (e) => {
    e.preventDefault();
    const sanitizedUrl = sanitizeInput(newWebhookUrl);
    if (!sanitizedUrl) {
      showToast('Error', 'Please provide a valid webhook URL.', 'error');
      return;
    }

    const sanitizedHeaders = newWebhookHeaders
      .filter(h => h.key && h.value)
      .map(h => ({
        key: sanitizeInput(h.key),
        value: sanitizeInput(h.value)
      }));

    const { data, error } = await supabase
      .from('user_webhooks')
      .insert([
        { user_id: userData.id, url: sanitizedUrl, headers: sanitizedHeaders }
      ])
      .select('id, url, headers');

    if (error) {
      console.error('Error adding webhook:', error);
      showToast('Error adding webhook', error.message, 'error');
    } else {
      setNewWebhookUrl('');
      setNewWebhookHeaders([{ key: '', value: '' }]);
      setWebhooks([...webhooks, data[0]]);
      showToast('Webhook added', '', 'success');
    }
  };

  const handleDeleteWebhook = async (id) => {
    const { error } = await supabase
      .from('user_webhooks')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting webhook:', error);
      showToast('Error deleting webhook', error.message, 'error');
    } else {
      fetchWebhooks();
      showToast('Webhook deleted', '', 'success');
    }
  };

  const showToast = (title, message, type) => {
    const toast = document.getElementById('toast');
    const toastTitle = document.getElementById('toast-title');
    const toastMessage = document.getElementById('toast-message');
    
    toastTitle.textContent = title;
    toastMessage.textContent = message;
    toast.className = `alert alert-${type}`;
    toast.classList.remove('hidden');
    
    setTimeout(() => {
      toast.classList.add('hidden');
    }, 3000);
  };

  return (
    <div className={`space-y-6 ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Webhooks</h2>

      {isDisabled && (
        <div className="alert alert-warning">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            <span>Webhooks are only available for Enterprise tier users. Please upgrade your plan to access this feature.</span>
          </div>
        </div>
      )}

      <form onSubmit={handleAddWebhook} className="space-y-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">New Webhook URL</span>
          </label>
          <input
            type="text"
            value={newWebhookUrl}
            onChange={(e) => setNewWebhookUrl(e.target.value)}
            placeholder="https://example.com/webhook"
            className="input input-bordered w-full"
          />
        </div>
        {newWebhookHeaders.map((header, index) => (
          <div key={index} className="flex space-x-2">
            <input
              type="text"
              placeholder="Header Key"
              value={header.key}
              onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
              className="input input-bordered w-full"
            />
            <input
              type="text"
              placeholder="Header Value"
              value={header.value}
              onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
              className="input input-bordered w-full"
            />
            {index > 0 && (
              <button
                type="button"
                onClick={() => handleRemoveHeader(index)}
                className="btn btn-square btn-error"
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
            )}
          </div>
        ))}
        <div className="space-y-4"> {/* Added this div with space-y-4 class */}
          {newWebhookHeaders.length < 5 && (
            <button type="button" onClick={handleAddHeader} className="btn btn-outline btn-success w-full">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Header
            </button>
          )}
          <button type="submit" className="btn btn-primary w-full">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Webhook
          </button>
        </div>
      </form>

      {webhooks.length > 0 && (
        <div className="mt-8 space-y-4">
          <h3 className="text-xl font-bold text-gray-900 dark:text-white">Your Webhooks</h3>
          {webhooks.map((webhook) => (
            <div key={webhook.id} className="card bg-base-100 shadow-xl">
              <div className="card-body">
                <h4 className="card-title">URL: {webhook.url}</h4>
                <div>
                  <strong>Headers:</strong>
                  {webhook.headers && (
                    Array.isArray(webhook.headers) 
                      ? webhook.headers.map((header, index) => (
                          <p key={index} className="text-sm">{header.key}: {header.value}</p>
                        ))
                      : Object.entries(webhook.headers).map(([key, value], index) => (
                          <p key={index} className="text-sm">{key}: {value}</p>
                        ))
                  )}
                  {(!webhook.headers || (Array.isArray(webhook.headers) && webhook.headers.length === 0) || Object.keys(webhook.headers).length === 0) && (
                    <p className="text-sm">No headers</p>
                  )}
                </div>
                <div className="card-actions justify-end">
                  <button
                    onClick={() => handleDeleteWebhook(webhook.id)}
                    className="btn btn-sm btn-error"
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Toast */}
      <div id="toast" className="toast toast-top toast-end hidden">
        <div className="alert alert-success">
          <div>
            <span id="toast-title"></span>
            <span id="toast-message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhookSection;