import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SuspiciousIndicators = ({ scanData }) => {
  const renderSuspiciousIndicators = () => {
    if (scanData.suspicious_indicators && scanData.suspicious_indicators.length > 0) {
      const uniqueIndicators = [...new Set(scanData.suspicious_indicators)];
      return (
        <ul className="space-y-2" role="list">
          {uniqueIndicators.map((indicator, index) => (
            <li key={index} className="flex items-center space-x-3" role="listitem">
              <div className="flex-shrink-0 w-5 h-5 text-error" aria-hidden="true">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <span className="text-base-content">{indicator}</span>
            </li>
          ))}
        </ul>
      );
    } else {
      return <p className="text-base-content opacity-70">No suspicious indicators found.</p>;
    }
  };

  return (
    <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" aria-hidden="true" /> Suspicious Indicators
        </h2>
        {renderSuspiciousIndicators()}
      </div>
    </div>
  );
};

export default SuspiciousIndicators;
