import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';

const getImageUrl = (originalUrl, variant) => {
  if (!originalUrl) return null;
  return `${originalUrl}/${variant}`;
};

const Screenshot = ({ scanData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const previewUrl = getImageUrl(scanData.screenshot_url, 'preview');
  const fullsizeUrl = getImageUrl(scanData.screenshot_url, 'fullsize');

  return (
    <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
      <div className="card-body">
        <h2 className="card-title text-base-content mb-4">
          <FontAwesomeIcon icon={faCamera} className="mr-2" aria-hidden="true" /> Screenshot
        </h2>
        <div className="flex flex-col items-center w-full">
          {scanData.screenshot_url ? (
            <div
              className="max-w-3xl w-full border border-base-300 rounded-lg overflow-hidden relative cursor-pointer"
              onClick={openModal}
              role="button"
              aria-label="Open full-size screenshot"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  openModal();
                }
              }}
            >
              <img
                src={previewUrl}
                alt="Website Screenshot"
                className="w-full h-auto object-contain"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "path/to/fallback/image.png"; // Replace with your fallback image path
                }}
              />
              <div className="badge badge-primary absolute top-2 right-2">
                Screenshot
              </div>
            </div>
          ) : (
            <p className="text-base-content opacity-70">No screenshot available.</p>
          )}
          <p className="text-sm text-base-content opacity-70 mt-2">
            Screenshot taken on: {new Date(scanData.timestamp).toLocaleString()}
          </p>
        </div>
      </div>

      {isOpen && (
        <div className="modal modal-open">
          <div className="modal-box max-w-full h-full p-0 relative">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2 z-10"
              onClick={closeModal}
            >
              <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
            </button>
            <img
              src={fullsizeUrl}
              alt="Full-size Website Screenshot"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Screenshot;
