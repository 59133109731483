// utils/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  }
});

export const signInWithOtp = async (email) => {
  try {
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
    });
    console.log('signInWithOtp response:', { data, error });
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error during signInWithOtp:', error);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.origin
      }
    });
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error during Google sign in:', error);
    throw error;
  }
};

export const subscribeToRipperDetails = (userId, callback) => {
  return supabase.channel('scan_updates')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'ripper_details',
        filter: `linked_user=eq.${userId} AND pct_complete=gte.80`,
      },
      callback
    )
    .subscribe((status) => {
      if (status === 'SUBSCRIBED') {
        console.log('Subscribed to scan updates');
      }
    });
};

export const fetchNotifications = async (userId) => {

  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(10);

  if (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }

  return data;
};

export const createNotification = async (userId, message, scanId) => {

  const { data, error } = await supabase
    .from('notifications')
    .insert([
      {
        user_id: userId,
        message,
        scan_id: scanId,
        created_at: new Date().toISOString(),
      }
    ])
    .select();

  if (error) {
    console.error('Error creating notification:', error);
    throw error;
  }

  return data[0];
};

export const checkCompletedScans = async (userId) => {

  
  // Calculate the timestamp for one hour ago
  const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000).toISOString();

  const { data, error } = await supabase
    .from('ripper_details')
    .select('job_id, original_url, pct_completed, notified, timestamp')
    .eq('linked_user', userId)
    .gte('pct_completed', 80)
    .eq('notified', false)
    .gte('timestamp', oneHourAgo)
    .order('timestamp', { ascending: false });

  if (error) {
    console.error('Error checking completed scans:', error);
    throw error;
  }
  

  return data;
};

export const markScanAsNotified = async (jobId) => {

  const { data, error } = await supabase
    .from('ripper_details')
    .update({ notified: true })
    .eq('job_id', jobId)
    .select();

  if (error) {
    console.error('Error marking scan as notified:', error);
    throw error;
  }
  if (data && data.length > 0) {

    return data[0];
  } else {
    console.error('No scan updated');
    throw new Error('No scan updated');
  }
};

export const markNotificationAsRead = async (notificationId) => {

  const { data, error } = await supabase
    .from('notifications')
    .update({ read: true })
    .eq('id', notificationId)
    .select();

  if (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }

  return data[0];
};
