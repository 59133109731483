import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faRedo, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ExistingScanModal = ({ isOpen, onClose, existingScan, onViewExisting, onScanAgain }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 text-yellow-500" />
            URL Already Scanned
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <p className="mb-6 text-gray-700 dark:text-gray-300">
          This URL has already been scanned on {new Date(existingScan?.timestamp).toLocaleString()}. 
          What would you like to do?
        </p>
        <div className="flex justify-center space-x-4">
          <button 
            onClick={onViewExisting} 
            className="btn btn-primary flex items-center"
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View Existing Scan
          </button>
          <button 
            onClick={onScanAgain} 
            className="btn btn-secondary flex items-center"
          >
            <FontAwesomeIcon icon={faRedo} className="mr-2" />
            Scan Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExistingScanModal;
