import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEnvelope, faLock, faFont, faCalendar, faSearch, faCheck, faList, faFile, faQuestion } from '@fortawesome/free-solid-svg-icons';

const FormDataDisplay = ({ formDetails }) => {
  const [expandedForms, setExpandedForms] = useState([]);

  const getInputIcon = (type) => {
    switch (type) {
      case 'email':
        return faEnvelope;
      case 'password':
        return faLock;
      case 'text':
        return faFont;
      case 'date':
        return faCalendar;
      case 'search':
        return faSearch;
      case 'checkbox':
        return faCheck;
      case 'select':
        return faList;
      case 'file':
        return faFile;
      default:
        return faQuestion;
    }
  };

  const toggleForm = (index) => {
    setExpandedForms(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  const groupInputsByType = (inputs) => {
    return inputs.reduce((acc, input) => {
      if (!acc[input.type]) {
        acc[input.type] = [];
      }
      acc[input.type].push(input);
      return acc;
    }, {});
  };

  return (
    <div className="space-y-4">
      {formDetails.map((form, formIndex) => (
        <div key={formIndex} className="bg-base-200 p-4 rounded-lg">
          <button 
            className="flex justify-between items-center w-full text-left font-semibold"
            onClick={() => toggleForm(formIndex)}
          >
            <span>Form {formIndex + 1} ({form.method.toUpperCase()} {form.action || 'No action specified'})</span>
            <FontAwesomeIcon icon={expandedForms.includes(formIndex) ? faChevronUp : faChevronDown} />
          </button>
          
          {expandedForms.includes(formIndex) && (
            <div className="mt-4">
              {Object.entries(groupInputsByType(form.inputs)).map(([type, inputs]) => (
                <div key={type} className="mb-4">
                  <h4 className="text-md font-semibold mb-2 flex items-center">
                    <FontAwesomeIcon icon={getInputIcon(type)} className="mr-2 text-primary" />
                    {type.charAt(0).toUpperCase() + type.slice(1)} ({inputs.length})
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {inputs.map((input, inputIndex) => (
                      <div key={inputIndex} className="bg-base-100 p-2 rounded-md text-sm">
                        <p><strong>Name:</strong> {input.name || 'Not specified'}</p>
                        {input.value !== undefined && (
                          <p><strong>Value:</strong> {input.value === '' ? '(empty)' : input.value}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FormDataDisplay;
