import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  const fetchReleaseNotes = async () => {
    try {
      const { data, error } = await supabase
        .from('release_notes')
        .select('version, release_date, content')
        .order('release_date', { ascending: false });

      if (error) throw error;
      setReleaseNotes(data);
    } catch (error) {
      console.error('Error fetching release notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-primary" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="mb-8">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Release Notes</h2>
        <p className="text-gray-600 dark:text-gray-400 mt-2">Latest updates and improvements to QSI AI.url</p>
      </div>

      <div className="space-y-12">
        {releaseNotes.map((note, index) => (
          <div key={note.id} className="relative pl-8">
            {/* Version badge */}
            <div className="inline-block px-4 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
              Version {note.version}
            </div>
            
            {/* Release date */}
            <div className="text-sm text-gray-600 dark:text-gray-400 mb-6">
              {formatDate(note.release_date)}
            </div>

            {/* Content */}
            <div className="markdown-content">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{note.content}</ReactMarkdown>
            </div>

            {/* Timeline elements */}
            {index !== releaseNotes.length - 1 && (
              <div className="absolute left-[3px] top-3 h-[calc(100%-12px)]">
                <div className="h-full w-0.5 bg-gray-200 dark:bg-gray-700"></div>
              </div>
            )}
            <div className="absolute left-[0px] top-0 w-2 h-2 rounded-full bg-primary ring-2 ring-white dark:ring-gray-900"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReleaseNotes;
