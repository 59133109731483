import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faExclamationCircle, faExternalLinkAlt, faChevronRight, faCheckCircle, faExclamationTriangle, faTimesCircle, faQuestionCircle, faSkull, faHeartbeat, faHistory, faRobot, faShieldAlt, faChevronDown, faSliders } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { analyzeUrl } from '../services/urlAnalysisService';
import DOMPurify from 'dompurify';
import { Turnstile } from '@marsidev/react-turnstile';
import ExistingScanModal from './ExistingScanModal';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import LoginModal from './LoginModal';

const AIURLScan = () => {
  const { session, userData, setUserData } = useContext(AuthContext);
  const [url, setUrl] = useState('');
  const [jobId, setJobId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [error, setError] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const navigate = useNavigate();
  const [isTurnstileVisible, setIsTurnstileVisible] = useState(false);
  const turnstileRef = useRef(null);
  const [recentScans, setRecentScans] = useState([]);
  const [showExistingScanModal, setShowExistingScanModal] = useState(false);
  const [existingScan, setExistingScan] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [obfuscateEmail, setObfuscateEmail] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [bypassExistingScanCheck, setBypassExistingScanCheck] = useState(false);
  const [scanStartTime, setScanStartTime] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showMyScansOnly, setShowMyScansOnly] = useState(false);

  useEffect(() => {
    fetchRecentScans();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setLoadingMessage('');
      setScanStartTime(null);
      return;
    }

    setScanStartTime(Date.now());
    
    const messageSequence = [
      { time: 0, message: 'Initiating scan...' },
      { time: 5000, message: 'Checking URL accessibility...' },
      { time: 10000, message: 'Analyzing domain information and SSL certificates...' },
      { time: 15000, message: 'Capturing website screenshot...' },
      { time: 20000, message: 'Processing security indicators...' },
      { time: 30000, message: 'Running AI analysis on gathered data...' },
      { time: 45000, message: 'Finalizing analysis...' },
    ];

    const messageTimers = messageSequence.map(({ time, message }) => {
      return setTimeout(() => {
        setLoadingMessage(message);
      }, time);
    });

    return () => {
      messageTimers.forEach(timer => clearTimeout(timer));
    };
  }, [isLoading]);

  useEffect(() => {
    fetchRecentScans();
  }, [showMyScansOnly]);

  const fetchRecentScans = async () => {
    let query = supabase
      .from('ripper_details')
      .select(`
        job_id, 
        original_url, 
        parsed_domain, 
        final_score, 
        alive, 
        ai_analysis->risk_score,
        users(name, avatar_url)
      `)
      .order('timestamp', { ascending: false })
      .limit(20);

    // Add filter for my scans if enabled
    if (showMyScansOnly && session) {
      query = query.eq('linked_user', session.user.id);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching recent scans:', error);
    } else {
      setRecentScans(data);
    }
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleInputFocus = () => {
    if (!session) {
      setOpenLoginModal(true);
    }
  };

  const sanitizeUrl = (input) => {
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    const urlPattern = /^(https?:\/\/)?((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([\w-]+\.)+[a-z]{2,}))(\/[\w\-.~!$&'()*+,;=:@/%?#]*)?$/i;
    return urlPattern.test(sanitized) ? sanitized : '';
  };

  const checkExistingScan = async (sanitizedUrl) => {
    try {
      const { data, error } = await supabase
        .from('ripper_details')
        .select('job_id, original_url, timestamp')
        .eq('original_url', sanitizedUrl)
        .order('timestamp', { ascending: false })
        .limit(1);

      if (error) throw error;

      if (data && data.length > 0) {
        setExistingScan(data[0]);
        setShowExistingScanModal(true);
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error checking for existing scan:', error);
      return false;
    }
  };

  const handleScan = async () => {
    if (!isTurnstileVisible) {
      setIsTurnstileVisible(true);
      return;
    }

    try {
      setError(null);
      setIsLoading(true);

      let sanitizedUrl = sanitizeUrl(url);
      if (!sanitizedUrl) {
        setError('Please enter a valid URL.');
        setIsLoading(false);
        return;
      }

      // Obfuscate email if the option is selected
      if (obfuscateEmail) {
        sanitizedUrl = obfuscateEmailInUrl(sanitizedUrl);
      }

      // Only check for existing scan if not bypassing
      if (!bypassExistingScanCheck) {
        const existingScanFound = await checkExistingScan(sanitizedUrl);
        if (existingScanFound) {
          setIsLoading(false);
          return;
        }
      }

      // Reset bypass flag after use
      setBypassExistingScanCheck(false);

      const jobId = await analyzeUrl(sanitizedUrl, session.user.id, isPrivate);
      setJobId(jobId);

      if (userData.num_requests <= 0) {
        setError(
          <div className="flex flex-col items-center">
            <p className="mb-2">You have no scans left this month.</p>
            <RouterLink 
              to="/pricing"
              className="btn btn-primary btn-sm"
            >
              Upgrade Your Plan
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </RouterLink>
          </div>
        );
        setIsLoading(false);
        return;
      }

      const newRequestCount = userData.num_requests - 1;

      const { error } = await supabase
        .from('users')
        .update({ num_requests: newRequestCount })
        .eq('id', session.user.id);

      if (error) {
        console.error('Error updating request count:', error);
        setError('Error updating request count. Please try again.');
      } else {
        setUserData({ ...userData, num_requests: newRequestCount });
      }

      navigate(`/results/${jobId}`);
    } catch (error) {
      console.error('Error scanning URL:', error);
      setError('Error scanning URL. Please try again.');
    } finally {
      setIsLoading(false);
      setIsTurnstileVisible(false);
      turnstileRef.current?.reset();
    }
  };

  const onTurnstileSuccess = async (token) => {
    await handleScan();
  };

  const handleViewExistingScan = () => {
    navigate(`/results/${existingScan.job_id}`);
    setShowExistingScanModal(false);
  };

  const handleContinueScan = async () => {
    setShowExistingScanModal(false);
    setExistingScan(null);
    setBypassExistingScanCheck(true); // Set the bypass flag
    await handleScan();
  };

  const obfuscateEmailInUrl = (url) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return url.replace(emailRegex, 'mary.robinson@qsi.net');
  };

  // ... (keep the existing helper functions like truncateUrl, getRiskScore, getRiskColor, etc.)

  const getStatusMessage = (jobStatus) => {
    switch (jobStatus) {
      case 'Starting':
        return "Starting up...";
      case 'Checking':
        return "Checking URL safety...";
      case 'Gathering':
        return "Gathering DNS, SSL, and IP info...";
      case 'Capturing':
        return "Capturing screenshot...";
      case 'Processing':
        return "Analyzing with AI...";
      case 'Finalizing':
        return "Finalizing and completing the analysis...";
      case 'Completed':
        return "Completed";
      default:
        return "Processing, please allow up to 1 minute...";
    }
  };

  const truncateUrl = (url, maxLength = 200) => {
    if (!url) return '';
    if (url.length <= maxLength) return url;
    return `${url.slice(0, maxLength)}...`;
  };

  const getRiskScore = (scan) => {
    if (scan.risk_score !== null && scan.risk_score !== undefined) {
      return scan.risk_score;
    }
    return scan.final_score || null;
  };

  const getRiskColor = (scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'bg-gray-100 text-gray-800';
    if (score > 75) return 'bg-red-100 text-red-800';
    if (score >= 35) return 'bg-yellow-100 text-yellow-800';
    return 'bg-green-100 text-green-800';
  };

  const getRiskText = (scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'N/A';
    if (score > 75) return 'High';
    if (score >= 35) return 'Moderate';
    return 'Low';
  };

  const getStatusIcon = (scan) => {
    if (scan.alive === 'alive') return faHeartbeat;
    if (scan.alive === 'not alive') return faSkull;
    return faQuestionCircle;
  };

  const getStatusColor = (scan) => {
    if (scan.alive === 'alive') return 'text-green-600 dark:text-green-400';
    if (scan.alive === 'not alive') return 'text-red-600 dark:text-red-400';
    return 'text-gray-600 dark:text-gray-400';
  };

  const getStatusTooltip = (scan) => {
    if (scan.alive === 'alive') return 'WAS_LIVE';
    if (scan.alive === 'not alive') return 'WAS_DEAD';
    return 'UNKNOWN';
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col items-center mb-8">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faShieldAlt} className="text-4xl text-secondary mr-4" />
            <h1 className="text-3xl font-bold text-base-content">Intelligent URL Analysis</h1>
          </div>
        </div>
        
        <div className="w-full max-w-3xl mx-auto">
          {session && (
            <div className="flex justify-center items-center mb-4">
              <div className="tooltip" data-tip={userData?.num_requests <= 0 
                ? "You've used all your scans. Upgrade for more!" 
                : "All accounts have varying levels of scan allotment. View your profile to review your subscription."}>
                <span className={`badge ${
                  userData?.num_requests <= 0 ? 'badge-error' 
                  : userData?.num_requests <= 5 ? 'badge-warning'
                  : 'badge-success'
                } p-3 text-sm`}>
                  Scans Remaining: {userData?.num_requests || 0}
                </span>
              </div>
              {userData?.num_requests <= 5 && (
                <RouterLink 
                  to="/pricing"
                  className="btn btn-sm btn-primary ml-4"
                >
                  Upgrade Plan
                  <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </RouterLink>
              )}
            </div>
          )}
          
          <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
            <div className="card-body">
              <div className="flex flex-col items-center w-full">
                <div className="join w-full mb-4">
                  <input 
                    type="text"
                    placeholder="Enter URL to scan"
                    value={url}
                    onChange={handleUrlChange}
                    onFocus={handleInputFocus}
                    disabled={isLoading}
                    className="input input-bordered join-item w-full"
                  />
                  <button
                    onClick={handleScan}
                    disabled={isLoading || url.trim() === ''}
                    className="btn btn-primary join-item"
                  >
                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                    Scan
                  </button>
                </div>
                
                <div className="w-full mb-4">
                  <button 
                    onClick={() => setShowOptions(!showOptions)} 
                    className="btn btn-sm btn-ghost w-full flex justify-between items-center hover:bg-transparent"
                  >
                    <span className="flex items-center">
                      <FontAwesomeIcon icon={faSliders} className="mr-2" />
                      Options
                    </span>
                    <FontAwesomeIcon 
                      icon={faChevronDown} 
                      className={`transition-transform duration-200 ${showOptions ? 'rotate-180' : ''}`} 
                    />
                  </button>
                  {showOptions && (
                    <div className="mt-2 p-4 border border-base-300 rounded-lg">
                      <div className="form-control">
                        <label className="label cursor-pointer">
                          <span className="label-text">
                            Obfuscate Email Address
                            <div className="tooltip tooltip-right" data-tip="Replaces any email address in the URL with a generic one to protect privacy during scanning.">
                              <FontAwesomeIcon icon={faQuestionCircle} className="ml-2 text-base-content/60" />
                            </div>
                          </span> 
                          <input 
                            type="checkbox" 
                            className="checkbox checkbox-primary" 
                            checked={obfuscateEmail}
                            onChange={(e) => setObfuscateEmail(e.target.checked)}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {isTurnstileVisible && (
                  <div className="mt-4">
                    <Turnstile
                      ref={turnstileRef}
                      siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                      onSuccess={onTurnstileSuccess}
                      onError={() => setError('Turnstile verification failed. Please try again.')}
                      onExpire={() => setIsTurnstileVisible(false)}
                      options={{
                        theme: 'light',
                      }}
                    />
                  </div>
                )}
              </div>

              {isLoading && (
                <div className="w-full mt-4">
                  <progress className="progress progress-primary w-full"></progress>
                  <div className="flex items-center justify-center mt-2">
                    <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2 text-primary" />
                    <p className="text-primary">{loadingMessage}</p>
                  </div>
                  {scanStartTime && Date.now() - scanStartTime > 60000 && (
                    <p className="text-sm text-warning text-center mt-2">
                      <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                      This scan is taking longer than usual. Please be patient...
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="mt-12">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faHistory} className="text-2xl text-primary" />
              <h2 className="text-2xl font-bold text-base-content">Recent Scans</h2>
            </div>
            <div className="flex items-center space-x-4">
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text mr-2">My Scans</span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={showMyScansOnly}
                    onChange={(e) => setShowMyScansOnly(e.target.checked)}
                    disabled={!session}
                  />
                </label>
              </div>
              <div className="bg-base-200 rounded-full px-4 py-2">
                <RouterLink 
                  to="/recent-scans" 
                  className="text-sm font-medium text-base-content hover:text-primary transition-colors duration-200 flex items-center"
                >
                  See all
                  <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </RouterLink>
              </div>
            </div>
          </div>
          <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
            <div className="card-body">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th className="text-xs sm:text-sm">URL</th>
                      <th className="text-xs sm:text-sm text-center">Submitter</th>
                      <th className="text-xs sm:text-sm text-center hidden sm:table-cell">Domain</th>
                      <th className="text-xs sm:text-sm text-center">Risk</th>
                      <th className="text-xs sm:text-sm text-center hidden sm:table-cell">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <AnimatePresence initial={false}>
                      {recentScans.map((scan) => (
                        <motion.tr
                          key={scan.job_id}
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <td className="text-xs sm:text-sm p-2 sm:p-4">
                            <RouterLink to={`/results/${scan.job_id}`} className="cursor-pointer hover:underline">
                              {truncateUrl(scan.original_url, window.innerWidth < 640 ? 30 : 60)}
                            </RouterLink>
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1 text-xs sm:text-sm text-base-content opacity-50" />
                          </td>
                          <td className="p-2 sm:p-4 text-center">
                            <div className="flex items-center justify-center gap-2">
                              {scan.users?.avatar_url ? (
                                <img 
                                  src={scan.users.avatar_url} 
                                  alt={scan.users.name}
                                  className="w-6 h-6 rounded-full"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://ui-avatars.com/api/?name=" + encodeURIComponent(scan.users?.name || 'System');
                                  }}
                                />
                              ) : (
                                <div className="w-6 h-6 rounded-full bg-primary/10 flex items-center justify-center">
                                  <span className="text-xs text-primary">
                                    {(scan.users?.name || 'S')[0].toUpperCase()}
                                  </span>
                                </div>
                              )}
                              <span className="text-xs sm:text-sm">
                                {scan.users?.name || 'System'}
                              </span>
                            </div>
                          </td>
                          <td className="text-xs sm:text-sm p-2 sm:p-4 text-center hidden sm:table-cell">
                            {truncateUrl(scan.parsed_domain || 'N/A', 40)}
                          </td>
                          <td className="text-center p-2 sm:p-4">
                            <span className={`badge ${getRiskColor(scan)} text-2xs sm:text-xs whitespace-nowrap px-2 sm:px-3 py-1 sm:py-2`}>
                              {getRiskText(scan)}
                            </span>
                          </td>
                          <td className="p-2 sm:p-4 text-center hidden sm:table-cell">
                            <span 
                              className={`inline-flex items-center justify-center w-5 h-5 sm:w-6 sm:h-6 rounded-full ${getStatusColor(scan)}`}
                              title={getStatusTooltip(scan)}
                            >
                              <FontAwesomeIcon icon={getStatusIcon(scan)} className="text-xs sm:text-sm" />
                            </span>
                          </td>
                        </motion.tr>
                      ))}
                    </AnimatePresence>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExistingScanModal
        isOpen={showExistingScanModal}
        onClose={() => setShowExistingScanModal(false)}
        existingScan={existingScan}
        onViewExisting={handleViewExistingScan}
        onScanAgain={handleContinueScan}
      />

      <LoginModal isOpen={openLoginModal} onClose={() => setOpenLoginModal(false)} />
    </DashboardLayout>
  );
};

export default AIURLScan;
