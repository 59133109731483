import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faImage, faCode, faFont, faQuestion, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

const HTTPTransactionsTable = ({ transactions }) => {
  const getStatusColor = (status) => {
    if (status >= 200 && status < 300) return 'text-success';
    if (status >= 300 && status < 400) return 'text-info';
    if (status >= 400) return 'text-error';
    return 'text-base-content';
  };

  const getIcon = (type) => {
    if (!type) return faQuestion; // Handle undefined or null type
    if (type.startsWith('image')) return faImage;
    if (type.includes('javascript') || type.includes('json')) return faCode;
    if (type.includes('html') || type.includes('xml')) return faCode;
    if (type.includes('css')) return faFont;
    if (type.includes('text')) return faFile;
    return faQuestion;
  };

  const isHttps = (url) => url.toLowerCase().startsWith('https://');

  const formatSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Parse the JSON string if it's not already an array
  const parsedTransactions = Array.isArray(transactions) 
    ? transactions 
    : JSON.parse(transactions);

  if (!Array.isArray(parsedTransactions) || parsedTransactions.length === 0) {
    return <p>No HTTP transactions data available.</p>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="table table-zebra w-full">
        <thead>
          <tr>
            <th></th>
            <th>Method</th>
            <th>Resource</th>
            <th>Status</th>
            <th>Size</th>
            <th>Time (ms)</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {parsedTransactions.map((t, index) => (
            <tr key={t.sequence || index}>
              <td>
                {isHttps(t.resource_path) ? (
                  <FontAwesomeIcon icon={faLock} className="text-success" />
                ) : (
                  <FontAwesomeIcon icon={faLockOpen} className="text-error" />
                )}
              </td>
              <td>{t.method}</td>
              <td className="max-w-xs truncate" title={t.resource_path}>
                {t.resource_path}
              </td>
              <td className={getStatusColor(t.status)}>{t.status}</td>
              <td>{formatSize(t.size)}</td>
              <td>{typeof t.time === 'number' ? t.time.toFixed(2) : t.time}</td>
              <td>
                <FontAwesomeIcon icon={getIcon(t.type)} className="mr-2" />
                {t.type || 'Unknown'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HTTPTransactionsTable;
