import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { ThemeContext } from '../utils/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShieldAlt, faSearch, faHistory, faCalendar, faFingerprint, faTags, faArrowUp, faChevronRight, faArrowDown, faCheckCircle, faQuestionCircle, faExclamationCircle, faHeartbeat, faSkull, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns'; // You might need to install this package
import { Helmet } from 'react-helmet-async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const Dashboard = () => {
  const { session, userData } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [stats, setStats] = useState({
    totalScans: 0,
    maliciousScans: 0,
    safeSites: 0,
    recentScans: [],
    scanHistory: [],
  });
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [huntingLeads, setHuntingLeads] = useState({
    jarmFingerprints: [],
    commonTags: [],
  });
  const [tagTrends, setTagTrends] = useState([]);
  const [prevStats, setPrevStats] = useState({
    totalScans: 0,
    maliciousScans: 0,
    safeSites: 0,
  });

  useEffect(() => {
    if (session) {
      fetchDashboardData();
      fetchPreviousPeriodData();
      fetchHuntingLeads();
      fetchTagTrends();
    }
  }, [session, startDate, endDate]);

  const fetchDashboardData = async () => {
    if (!session) return;

    setLoading(true);

    // Fetch total scans within the date range
    const { count: totalScans, error: totalError } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString());

    if (totalError) {
      console.error('Error fetching total scans:', totalError);
    }

    // Fetch malicious scans within the date range
    const { count: maliciousScans, error: maliciousError } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .eq('linked_user', session.user.id)
      .gt('final_score', 75)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString());

    if (maliciousError) {
      console.error('Error fetching malicious scans:', maliciousError);
    }

    // Calculate safe sites
    const safeSites = totalScans - maliciousScans;

    // Fetch recent scans within the date range
    const { data: recentScans, error: recentError } = await supabase
      .from('ripper_details')
      .select('id, job_id, original_url, timestamp, final_score')
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString())
      .order('timestamp', { ascending: false })
      .limit(5);

    if (recentError) {
      console.error('Error fetching recent scans:', recentError);
    }

    // Fetch scan history for the selected date range
    const { data: scanHistory, error: historyError } = await supabase
      .from('ripper_details')
      .select('timestamp')
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString())
      .order('timestamp', { ascending: true });

    if (historyError) {
      console.error('Error fetching scan history:', historyError);
    }

    setStats({
      totalScans: totalScans || 0,
      maliciousScans: maliciousScans || 0,
      safeSites: safeSites || 0,
      recentScans: recentScans || [],
      scanHistory: scanHistory || [],
    });
    setLoading(false);
  };

  const fetchPreviousPeriodData = async () => {
    if (!session) return;

    const periodDuration = endDate.getTime() - startDate.getTime();
    const prevStartDate = new Date(startDate.getTime() - periodDuration);
    const prevEndDate = new Date(startDate);

    // Fetch total scans for the previous period
    const { count: prevTotalScans, error: prevTotalError } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .eq('linked_user', session.user.id)
      .gte('timestamp', prevStartDate.toISOString())
      .lt('timestamp', prevEndDate.toISOString());

    if (prevTotalError) {
      console.error('Error fetching previous total scans:', prevTotalError);
    }

    // Fetch malicious scans for the previous period
    const { count: prevMaliciousScans, error: prevMaliciousError } = await supabase
      .from('ripper_details')
      .select('id', { count: 'exact' })
      .eq('linked_user', session.user.id)
      .gt('final_score', 75)
      .gte('timestamp', prevStartDate.toISOString())
      .lt('timestamp', prevEndDate.toISOString());

    if (prevMaliciousError) {
      console.error('Error fetching previous malicious scans:', prevMaliciousError);
    }

    // Calculate previous safe sites
    const prevSafeSites = prevTotalScans - prevMaliciousScans;

    setPrevStats({
      totalScans: prevTotalScans || 0,
      maliciousScans: prevMaliciousScans || 0,
      safeSites: prevSafeSites || 0,
    });
  };

  const calculateTrend = (current, previous) => {
    if (previous === 0) return 100;
    return ((current - previous) / previous) * 100;
  };

  const fetchHuntingLeads = async () => {
    if (!session) return;

    // Fetch JARM fingerprints
    const { data: jarmData, error: jarmError } = await supabase
      .from('ripper_details')
      .select('jarm_fingerprint')
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString())
      .not('jarm_fingerprint', 'eq', null);

    if (jarmError) {
      console.error('Error fetching JARM fingerprints:', jarmError);
    }

    // Fetch tags
    const { data: tagData, error: tagError } = await supabase
      .from('ripper_details')
      .select('ai_analysis')
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString())
      .not('ai_analysis', 'eq', null);

    if (tagError) {
      console.error('Error fetching tags:', tagError);
    }

    // Process JARM fingerprints
    const jarmFingerprints = jarmData
      ? jarmData
          .map(item => {
            const match = item.jarm_fingerprint?.match(/JARM:\s*([a-f0-9]+)/i);
            return match ? match[1] : null;
          })
          .filter(Boolean)
          .reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
          }, {})
      : {};

    // Process tags
    const allTags = tagData
      ? tagData.flatMap(item => {
          if (typeof item.ai_analysis === 'object' && Array.isArray(item.ai_analysis.tags)) {
            return item.ai_analysis.tags;
          } else if (typeof item.ai_analysis?.analysis === 'string') {
            try {
              const parsedAnalysis = JSON.parse(item.ai_analysis.analysis.replace(/```json|```/g, ''));
              return parsedAnalysis.tags || [];
            } catch (error) {
              console.error("Error parsing ai_analysis for tags:", error);
              return [];
            }
          }
          return [];
        })
      : [];

    const tagCounts = allTags.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});

    setHuntingLeads({
      jarmFingerprints: Object.entries(jarmFingerprints)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5),
      commonTags: Object.entries(tagCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5),
    });
  };

  const fetchTagTrends = async () => {
    if (!session) return;

    const { data, error } = await supabase
      .from('ripper_details')
      .select('timestamp, ai_analysis')
      .eq('linked_user', session.user.id)
      .gte('timestamp', startDate.toISOString())
      .lte('timestamp', endDate.toISOString())
      .order('timestamp', { ascending: true });

    if (error) {
      console.error('Error fetching tag trends:', error);
      return;
    }

    const tagsByDate = {};
    data.forEach(item => {
      const date = new Date(item.timestamp).toISOString().split('T')[0];
      let tags = [];

      if (item.ai_analysis) {
        if (typeof item.ai_analysis === 'object' && Array.isArray(item.ai_analysis.tags)) {
          tags = item.ai_analysis.tags;
        } else if (typeof item.ai_analysis === 'object' && typeof item.ai_analysis.analysis === 'string') {
          try {
            const parsedAnalysis = JSON.parse(item.ai_analysis.analysis.replace(/```json|```/g, ''));
            tags = parsedAnalysis.tags || [];
          } catch (error) {
            console.error("Error parsing ai_analysis for tags:", error);
          }
        } else if (typeof item.ai_analysis === 'string') {
          try {
            const parsedAnalysis = JSON.parse(item.ai_analysis.replace(/```json|```/g, ''));
            tags = parsedAnalysis.tags || [];
          } catch (error) {
            console.error("Error parsing ai_analysis string for tags:", error);
          }
        }
      }

      if (!tagsByDate[date]) {
        tagsByDate[date] = {};
      }
      tags.forEach(tag => {
        tagsByDate[date][tag] = (tagsByDate[date][tag] || 0) + 1;
      });
    });

    const topTags = Object.values(tagsByDate)
      .flatMap(Object.keys)
      .reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1;
        return acc;
      }, {});

    const top5Tags = Object.entries(topTags)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([tag]) => tag);

    const trendData = top5Tags.map(tag => ({
      label: tag,
      data: Object.keys(tagsByDate).map(date => ({
        x: date,
        y: tagsByDate[date][tag] || 0
      }))
    }));

    setTagTrends(trendData);
  };

  const prepareChartData = () => {
    const dates = {};
    stats.scanHistory.forEach(scan => {
      const date = new Date(scan.timestamp).toLocaleDateString();
      dates[date] = (dates[date] || 0) + 1;
    });

    const sortedDates = Object.keys(dates).sort((a, b) => new Date(a) - new Date(b));

    return {
      labels: sortedDates,
      datasets: [
        {
          label: 'Scans',
          data: sortedDates.map(date => dates[date]),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Scan History (Last 30 Days)',
      },
    },
  };

  const tagTrendsChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tag Trends Over Time',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d'
          }
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Frequency'
        },
        beginAtZero: true
      }
    }
  };

  const tagTrendsChartData = {
    datasets: tagTrends.map((trend, index) => ({
      label: trend.label,
      data: trend.data.map(point => ({
        x: new Date(point.x),
        y: point.y
      })),
      borderColor: `hsl(${index * 60}, 70%, 50%)`,
      backgroundColor: `hsla(${index * 60}, 70%, 50%, 0.5)`,
      fill: false,
      tension: 0.1
    }))
  };

  const getRiskScore = useCallback((scan) => {
    if (scan.ai_analysis && scan.ai_analysis.risk_score !== null && scan.ai_analysis.risk_score !== undefined) {
      return scan.ai_analysis.risk_score;
    }
    return scan.final_score || null;
  }, []);

  const getRiskColor = useCallback((scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'bg-gray-100 text-gray-800';
    if (score > 75) return 'bg-red-100 text-red-800';
    if (score >= 35) return 'bg-yellow-100 text-yellow-800';
    return 'bg-green-100 text-green-800';
  }, [getRiskScore]);

  const getRiskText = useCallback((scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'N/A';
    if (score > 75) return 'High';
    if (score >= 35) return 'Moderate';
    return 'Low';
  }, [getRiskScore]);

  const truncateUrl = useCallback((url, maxLength = 30) => {
    if (url.length <= maxLength) return url;
    return `${url.slice(0, maxLength)}...`;
  }, []);

  // If there's no session, redirect to the login page
  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Helmet>
        <title>QSI - Dashboard</title>
        <meta name="description" content="View your scan statistics and recent scans on the QSI dashboard." />
      </Helmet>
      <div className="container mx-auto py-6 px-4">
        {session ? (
          <>
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
              <div className="flex items-center mb-4 md:mb-0">
                <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="input input-bordered mr-2"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="input input-bordered"
                />
              </div>
              <h1 className="text-3xl font-bold">Dashboard</h1>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title">
                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                    Total Scans
                  </h2>
                  <p className="text-4xl font-bold">{stats.totalScans}</p>
                  <div className="card-actions justify-end">
                    <TrendIndicator value={calculateTrend(stats.totalScans, prevStats.totalScans)} />
                  </div>
                </div>
              </div>
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title text-error">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                    Malicious Sites
                  </h2>
                  <p className="text-4xl font-bold text-error">{stats.maliciousScans}</p>
                  <div className="card-actions justify-end">
                    <TrendIndicator value={calculateTrend(stats.maliciousScans, prevStats.maliciousScans)} />
                  </div>
                </div>
              </div>
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title text-success">
                    <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                    Safe Sites
                  </h2>
                  <p className="text-4xl font-bold text-success">{stats.safeSites}</p>
                  <div className="card-actions justify-end">
                    <TrendIndicator value={calculateTrend(stats.safeSites, prevStats.safeSites)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title">Recent Scans</h2>
                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <th className="text-xs sm:text-sm">URL</th>
                          <th className="text-xs sm:text-sm text-center">Risk</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.recentScans.map((scan) => (
                          <tr key={scan.id}>
                            <td className="text-xs sm:text-sm p-2 sm:p-4">
                              <Link to={`/results/${scan.job_id}`} className="cursor-pointer hover:underline">
                                {truncateUrl(scan.original_url, window.innerWidth < 640 ? 30 : 60)}
                              </Link>
                              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1 text-xs sm:text-sm text-base-content opacity-50" />
                            </td>
                            <td className="text-center p-2 sm:p-4">
                              <span className={`badge ${getRiskColor(scan)} text-2xs sm:text-xs whitespace-nowrap px-2 sm:px-3 py-1 sm:py-2`}>
                                {getRiskText(scan)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-actions justify-end mt-4">
                    <Link to="/recent-scans" className="btn btn-sm">
                      See all <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title">Scan History</h2>
                  <Line options={chartOptions} data={prepareChartData()} />
                </div>
              </div>
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title">Hunting Leads</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-semibold flex items-center">
                        <FontAwesomeIcon icon={faFingerprint} className="mr-2" />
                        Top JARM Fingerprints
                      </h3>
                      <ul className="list-none space-y-1">
                        {huntingLeads.jarmFingerprints.map(([jarm, count]) => (
                          <li key={jarm}>
                            <Link
                              to={`/recent-scans?jarm=${encodeURIComponent(jarm)}`}
                              className="flex items-center justify-between text-base-content hover:text-primary transition-colors duration-200"
                            >
                              <span className="break-all mr-2">{jarm} ({count})</span>
                              <FontAwesomeIcon icon={faExternalLinkAlt} className="text-xs flex-shrink-0" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold flex items-center">
                        <FontAwesomeIcon icon={faTags} className="mr-2" />
                        Common Tags
                      </h3>
                      <ul className="list-none space-y-1">
                        {huntingLeads.commonTags.map(([tag, count]) => (
                          <li key={tag}>
                            <Link
                              to={`/recent-scans?tag=${encodeURIComponent(tag)}`}
                              className="flex items-center justify-between text-base-content hover:text-primary transition-colors duration-200"
                            >
                              <span>{tag} ({count})</span>
                              <FontAwesomeIcon icon={faExternalLinkAlt} className="text-xs ml-2" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h2 className="card-title">Tag Trends</h2>
                  <Line 
                    options={tagTrendsChartOptions} 
                    data={tagTrendsChartData} 
                    key={`tag-trends-${tagTrends.length}`}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Welcome to QSI!</h1>
            <p className="mb-4">Please log in to view your dashboard and access all features.</p>
            <Link to="/login" className="btn btn-primary">
              Log In
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const TrendIndicator = ({ value }) => {
  const formattedValue = value.toFixed(1);
  const isPositive = value > 0;
  const icon = isPositive ? faArrowUp : faArrowDown;
  const colorClass = isPositive ? 'text-success' : 'text-error';

  return (
    <span className={`flex items-center ${colorClass}`}>
      <FontAwesomeIcon icon={icon} className="mr-1" />
      {formattedValue}% vs previous period
    </span>
  );
};

export default Dashboard;
