import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ConfirmPlanChangeModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  currentPlan, 
  newPlan, 
  effectiveDate,
  isDowngrade 
}) => {
  if (!isOpen) return null;

  const isFreeDowngrade = newPlan?.toLowerCase().includes('free');

  return (
    <div className="modal modal-open">
      <div className="modal-box relative">
        <h3 className="font-bold text-lg mb-4">
          {isFreeDowngrade ? 'Switch to Free Plan' : 'Confirm Plan Change'}
        </h3>
        
        <div className="space-y-4">
          <div className="flex items-start space-x-3">
            <FontAwesomeIcon 
              icon={faExclamationTriangle} 
              className={isDowngrade ? "text-warning text-xl" : "text-success text-xl"} 
            />
            <div>
              <p className="font-medium">
                {isFreeDowngrade 
                  ? "You're about to switch to the Free plan"
                  : isDowngrade 
                    ? "You're about to downgrade your plan" 
                    : "You're about to upgrade your plan"
                }
              </p>
              <p className="text-sm opacity-70">
                From <span className="font-medium">{currentPlan}</span> to{" "}
                <span className="font-medium">{newPlan}</span>
              </p>
            </div>
          </div>

          <div className="bg-base-200 p-4 rounded-lg">
            <h4 className="font-medium mb-2">What happens next:</h4>
            <ul className="space-y-2 text-sm">
              {isFreeDowngrade ? (
                <>
                  <li>• Your current plan benefits will remain active until {effectiveDate}</li>
                  <li>• After this date, your subscription will be cancelled</li>
                  <li>• You'll be moved to the Free plan with limited features</li>
                  <li>• You won't be billed anymore after the current period</li>
                </>
              ) : isDowngrade ? (
                <>
                  <li>• Your current plan benefits will remain active until {effectiveDate}</li>
                  <li>• After this date, your plan will change to {newPlan}</li>
                  <li>• You'll be billed at the new rate starting {effectiveDate}</li>
                </>
              ) : (
                <>
                  <li>• You'll be redirected to complete payment</li>
                  <li>• Your new plan benefits will be available immediately</li>
                  <li>• Your billing cycle will update accordingly</li>
                </>
              )}
            </ul>
          </div>
        </div>

        <div className="modal-action mt-6">
          <button className="btn btn-ghost" onClick={onClose}>
            Cancel
          </button>
          <button 
            className={`btn ${isDowngrade ? 'btn-warning' : 'btn-primary'}`}
            onClick={onConfirm}
          >
            {isFreeDowngrade 
              ? 'Switch to Free Plan'
              : isDowngrade 
                ? 'Confirm Downgrade' 
                : 'Continue to Payment'
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPlanChangeModal;
