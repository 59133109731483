import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../utils/AuthContext';
import { supabase } from '../../utils/supabaseClient';

const VoteButtons = ({ scanId, scanUserId }) => {
  const { session } = useContext(AuthContext);
  const [voteStatus, setVoteStatus] = useState(null);
  const [voteCount, setVoteCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchVotes();
  }, [scanId]);

  const fetchVotes = async () => {
    const { data: votes, error } = await supabase
      .from('scan_votes')
      .select('vote_type')
      .eq('scan_id', scanId);

    if (!error) {
      const total = votes.reduce((acc, vote) => acc + (vote.vote_type ? 1 : -1), 0);
      setVoteCount(total);
    }

    if (session?.user) {
      const { data, error } = await supabase
        .from('scan_votes')
        .select('vote_type')
        .eq('scan_id', scanId)
        .eq('user_id', session.user.id)
        .single();

      if (!error && data) {
        setVoteStatus(data.vote_type);
      }
    }
  };

  const handleVote = async (voteType) => {
    if (!session || session.user.id === scanUserId) return;
    setIsLoading(true);
    
    try {
      if (voteStatus === voteType) {
        await supabase
          .from('scan_votes')
          .delete()
          .eq('scan_id', scanId)
          .eq('user_id', session.user.id);
        setVoteStatus(null);
      } else {
        await supabase
          .from('scan_votes')
          .upsert({
            scan_id: scanId,
            user_id: session.user.id,
            vote_type: voteType
          }, { onConflict: 'scan_id,user_id' });
        setVoteStatus(voteType);
      }
      await fetchVotes();
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center gap-1">
      <button
        onClick={() => handleVote(true)}
        disabled={isLoading || !session || session?.user.id === scanUserId}
        className="hover:opacity-100 p-0"
        title={!session ? 'Login to vote' : session?.user.id === scanUserId ? "Can't vote on own scans" : 'Upvote'}
      >
        <FontAwesomeIcon 
          icon={faArrowUp} 
          className={voteStatus === true ? 'text-success' : 'opacity-70 hover:opacity-100'} 
        />
      </button>
      <span className={`text-xs ${voteCount > 0 ? 'text-success' : voteCount < 0 ? 'text-error' : 'opacity-70'}`}>
        {voteCount}
      </span>
      <button
        onClick={() => handleVote(false)}
        disabled={isLoading || !session || session?.user.id === scanUserId}
        className="hover:opacity-100 p-0"
        title={!session ? 'Login to vote' : session?.user.id === scanUserId ? "Can't vote on own scans" : 'Downvote'}
      >
        <FontAwesomeIcon 
          icon={faArrowDown} 
          className={voteStatus === false ? 'text-error' : 'opacity-70 hover:opacity-100'} 
        />
      </button>
    </div>
  );
};

export default VoteButtons;