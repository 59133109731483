import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCopy, faInfoCircle, faChevronDown, faChevronUp, faCode, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const APIKeySection = ({ userData, isDisabled }) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [showRateLimits, setShowRateLimits] = useState(false);
  const [showUsageExample, setShowUsageExample] = useState(false);
  const [showTips, setShowTips] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(userData?.api_key || '');
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 2000);

    // Show toast
    document.getElementById('toast-success').classList.remove('hidden');
    setTimeout(() => {
      document.getElementById('toast-success').classList.add('hidden');
    }, 2000);
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const maskedApiKey = userData?.api_key ? '*'.repeat(userData.api_key.length) : '';

  const apiExample = `# Analyze a URL
curl -X POST \\
  https://slam.qsi.net/user/analyze \\
  -H 'Content-Type: application/json' \\
  -d '{
    "url": "https://example.com",
    "user_api_key": "YOUR_API_KEY_HERE"
  }'

# Check analysis status
curl -H "X-User-API-Key: YOUR_API_KEY_HERE" \\
  https://slam.qsi.net/user/status/ANALYSIS_ID_HERE`;

  const renderLockedContent = () => (
    <div className="alert alert-warning">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <span>API access is not available in the Free tier. Upgrade to Basic or higher for API access.</span>
      </div>
    </div>
  );

  return (
    <div className={`space-y-6 ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">API Key</h2>

      {isDisabled ? (
        renderLockedContent()
      ) : (
        <>
          <div>
            <label className="label">
              <span className="label-text">Your API Key:</span>
            </label>
            <div className="join w-full">
              <input
                type={showApiKey ? 'text' : 'password'}
                value={showApiKey ? userData?.api_key : maskedApiKey}
                readOnly
                className="input input-bordered join-item w-full"
              />
              <button 
                className="btn join-item bg-base-300 hover:bg-base-200 border-base-300 hover:border-base-200" 
                onClick={toggleApiKeyVisibility}
              >
                <FontAwesomeIcon icon={showApiKey ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>

          <button className="btn btn-primary" onClick={handleCopyClick} disabled={isDisabled}>
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            {hasCopied ? 'Copied!' : 'Copy API Key'}
          </button>

          {/* Collapsible API Rate Limits section */}
          <div className="collapse bg-base-200">
            <input 
              type="checkbox" 
              checked={showRateLimits}
              onChange={() => setShowRateLimits(!showRateLimits)}
            /> 
            <div className="collapse-title text-xl font-medium flex items-center">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              API Rate Limits
              <FontAwesomeIcon 
                icon={showRateLimits ? faChevronUp : faChevronDown} 
                className="ml-auto"
              />
            </div>
            <div className="collapse-content"> 
              <p className="text-sm">
                To ensure fair usage and optimal performance for all users, our API has a rate limit of 10 requests per 10 seconds based on your account's API key. This allows for efficient use of the API while maintaining service quality for everyone. If you need higher limits, please contact our support team to discuss enterprise options.
              </p>
            </div>
          </div>

          {/* Collapsible API Usage Example section */}
          <div className="collapse bg-base-200">
            <input 
              type="checkbox" 
              checked={showUsageExample}
              onChange={() => setShowUsageExample(!showUsageExample)}
            /> 
            <div className="collapse-title text-xl font-medium flex items-center">
              <FontAwesomeIcon icon={faCode} className="mr-2" />
              API Usage Example
              <FontAwesomeIcon 
                icon={showUsageExample ? faChevronUp : faChevronDown} 
                className="ml-auto"
              />
            </div>
            <div className="collapse-content"> 
              <div className="mockup-code bg-primary text-primary-content">
                {apiExample.split('\n').map((line, index) => (
                  <pre key={index} data-prefix={index + 1}>
                    <code>{line}</code>
                  </pre>
                ))}
              </div>
            </div>
          </div>

          {/* Collapsible API Tips section */}
          <div className="collapse bg-base-200">
            <input 
              type="checkbox" 
              checked={showTips}
              onChange={() => setShowTips(!showTips)}
            /> 
            <div className="collapse-title text-xl font-medium flex items-center">
              <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
              API Tips
              <FontAwesomeIcon 
                icon={showTips ? faChevronUp : faChevronDown} 
                className="ml-auto"
              />
            </div>
            <div className="collapse-content"> 
              <p className="text-sm mb-2">
                Use this API key to authenticate requests to our API. Do not share this key in publicly accessible areas such as GitHub, client-side code, or in API requests to other services.
              </p>
              <p className="text-sm">
                Replace 'YOUR_API_KEY_HERE' with your actual API key, and 'ANALYSIS_ID_HERE' with the ID returned from the analyze endpoint. API usage counts against your monthly quota.
              </p>
            </div>
          </div>

          {/* Toast message */}
          <div id="toast-success" className="toast toast-top toast-end hidden">
            <div className="alert alert-success">
              <span>API Key copied successfully!</span>
            </div>
          </div>
        </>
      )}

      {/* Toast message */}
      <div id="toast-success" className="toast toast-top toast-end hidden">
        <div className="alert alert-success">
          <span>API Key copied successfully!</span>
        </div>
      </div>
    </div>
  );
};

export default APIKeySection;
